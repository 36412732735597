import { Card } from "components/catalyst/card";
import { DropdownButton } from "components/catalyst/dropdown";
import { Heading } from "components/catalyst/heading";
import { CommonDropdown } from "components/common";
import { RouteNames } from '@vectroid/shared/const'
import { useTabs } from "hooks/use-tabs";
import { ChevronDown, PlusIcon } from "lucide-react";
import { useEffect } from "react";
import { DashboardEmptyState } from "../components/dashboard-empty-state";
import { DashboadRecentlyViewed } from "../components/dashboard-recently-viewed";
import { useDashboard } from "../store";

enum Tabs {
  RecentlyViewed = "recently-viewed",
  GettignStarted = "getting-started",
}

export function DashboardPage() {
  const { datasets, indexes, loading, fetchResources } = useDashboard();

  const { tabs, currentTab } = useTabs([
    {
      label: "Recently Viewed",
      href: Tabs.RecentlyViewed,
      current: true,
    },
    {
      label: "Learning Resources",
      href: Tabs.GettignStarted,
    },
  ]);

  useEffect(() => {
    fetchResources();
  }, []);

  function renderDashboard() {
    if (loading) {
      return <div>Loading...</div>;
    }

    if (Tabs.GettignStarted === currentTab) {
      return (
        <Card heading="Get started with Vectroid" className="max-w-xl">
          <DashboardEmptyState />
        </Card>
      );
    }

    return <DashboadRecentlyViewed datasets={datasets} indexes={indexes} />;
  }

  return (
    <>
      <Heading
        level={2}
        className="sm:text-lg/8"
        pageHeading
        tabs={tabs}
        actions={
          <CommonDropdown
            className="!p-0"
            anchor="bottom start"
            menuItems={[
              {
                href: RouteNames.CreateDataset,
                key: "dataset",
                label: "Dataset",
              },
              { href: RouteNames.CreateIndex, key: "index", label: "Index" },
              { href: RouteNames.ApiKeys, key: "api-key", label: "API Key" },
            ]}
          >
            <DropdownButton color="amber">
              <PlusIcon className="size-4" strokeWidth={3} /> Create New{" "}
              <ChevronDown className="size-6" />
            </DropdownButton>
          </CommonDropdown>
        }
      >
        Dashboard
      </Heading>

      {renderDashboard()}
    </>
  );
}
