import { Badge } from 'components/catalyst/badge'
import { ColumnDefinition, CommonTable } from 'components/common'
import { CommonDropdown } from 'components/common/dropdown/dropdown'
import { EllipsisVertical } from 'lucide-react'
import { useAuthStore } from '@vectroid/shared/auth'
import { CopyButton } from 'components/copy-button'

export function ApiKeysTable() {
  const apiKey = useAuthStore((state) => state.apiKey)

  const getApiKeyDisplayText = (key: string) => `${key?.slice(0, 6)}${'*'.repeat(26)}`

  const columns: ColumnDefinition<any>[] = [
    { key: 'id', header: 'ID' },
    { key: 'name', header: 'NAME' },
    {
      key: 'apiKey',
      header: 'API KEY',
      cell: (row) => (
        <div className="flex space-x-4">
          <Badge className="px-3">
            <code>{getApiKeyDisplayText(row.apiKey)}</code>
          </Badge>
          <CopyButton text={row.apiKey} />
        </div>
      ),
    },
    {
      key: 'actions',
      header: 'ACTIONS',
      cell: (row) => (
        <CommonDropdown
          buttonLabel={<EllipsisVertical size={16} />}
          menuItems={[
            { key: 'edit', label: 'Edit' },
            { key: 'delete', label: 'Delete' },
          ]}
        />
      ),
    },
  ]

  return (
    <CommonTable<any>
      columns={columns}
      data={[
        {
          id: 1,
          name: 'Default',
          apiKey: apiKey,
        },
      ]}
      loading={false}
    />
  )
}
