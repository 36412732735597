import { Button } from 'components/catalyst/button'
import { Card, DangerCard } from 'components/catalyst/card'
import { Divider } from 'components/catalyst/divider'
import { Field, Label } from 'components/catalyst/fieldset'
import { Subheading } from 'components/catalyst/heading'
import { Input } from 'components/catalyst/input'
import { Text } from 'components/catalyst/text'
import { useNotificationStore } from 'components/common'
import { ConsentDialog } from 'components/consent-dialog'
import { RouteNames } from '@vectroid/shared/const'
import { IndexDetailContext } from 'modules/indexes/context'
import { deleteIndexApi } from 'modules/indexes/services'
import { useIndexStore } from 'modules/indexes/store'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function SettingsPage() {
  const [loading, setLoading] = useState(false)
  const setMessage = useNotificationStore((state) => state.setMessage)
  const navigate = useNavigate()
  const indexDetail = useContext(IndexDetailContext)
  const { fetchIndexes } = useIndexStore()

  async function onSubmit() {
    try {
      setLoading(true)
      const response = await deleteIndexApi({ indexId: indexDetail.id })
      if (response) {
        setMessage(`<b>${indexDetail.name}</b> has been deleted. You are being redirected..`, { type: 'success' })
        setTimeout(() => {
          navigate(RouteNames.Indexes)
          fetchIndexes()
        }, 1500)
      }
    } catch (e: any) {
      console.log(e)
      setMessage(e.message, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="mt-4 max-w-4xl">
      <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="space-y-1">
          <Subheading>General Settings</Subheading>
          <Text>Update the general information of the index.</Text>
        </div>
        {/* Todo: Remove "pointer-events-none" after the panel gets activated */}
        <Card heading="General Settings" className="pointer-events-none">
          <Field>
            <Label>Index Name</Label>
            <Input aria-label="Index Name" name="name" defaultValue={indexDetail.name} />
          </Field>

          <Button className="mt-4" outline>
            Save
          </Button>
        </Card>
      </section>

      <Divider className="my-10" soft />

      <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="space-y-1">
          <Subheading>Delete Index</Subheading>
          <Text>
            The index will be permanently deleted, including its data and namespace. This action is irreversible and can
            not be undone.
          </Text>
        </div>
        <DangerCard heading="Delete Index Permanently">
          <ConsentDialog
            title="Delete Index"
            description="The index will be permanently deleted, including its data and namespace. This action is irreversible and can
            not be undone."
            confirmationWord={indexDetail.name}
            consentWord={'delete my index'}
            onConfirm={onSubmit}
          >
            <Button loading={loading} color="red">
              Delete Index
            </Button>
          </ConsentDialog>
        </DangerCard>
      </section>
    </div>
  )
}

export default SettingsPage
