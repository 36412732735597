import { SearchInput } from 'components/search-input'
import { useDatasetStore } from '../store'

export function DatasetsHeaderToolbar() {
  const { datasets } = useDatasetStore()

  if (!datasets.length) {
    return null
  }

  return (
    <div className="mb-6 flex gap-6">
      <SearchInput />
    </div>
  )
}
