import { Dialog, DialogBody, DialogDescription, DialogTitle } from 'components/catalyst/dialog'
import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import { Form } from './common/form/form'

type Props = {
  title: String
  description: String
  confirmationWord: String
  consentWord: String
  children: React.ReactNode
  onConfirm: () => void
}

export function ConsentDialog(props: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const formSchema = z.object({
    confirmationWord: z.string().refine((val) => val === props.confirmationWord, {
      message: '',
    }),
    consentWord: z.string().refine((val) => val === props.consentWord, {
      message: '',
    }),
  })

  type FormSchema = z.infer<typeof formSchema>

  const defaultValues: FormSchema = {
    confirmationWord: '',
    consentWord: '',
  }

  const formFields = [
    { name: 'confirmationWord', label: `Enter the name <b>${props.confirmationWord}</b> to continue`, autoFocus: true },
    { name: 'consentWord', label: `To verify, type <b>${props.consentWord}</b> below:` },
  ]

  function onSubmit(_: FormSchema, form: UseFormReturn<FormSchema>) {
    props.onConfirm()
    setIsOpen(false)
    form.reset()
  }

  return (
    <>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogDescription>{props.description}</DialogDescription>
        <DialogBody>
          <Form formFields={formFields} formSchema={formSchema} onSubmit={onSubmit} defaultValues={defaultValues} />
        </DialogBody>
      </Dialog>

      <span onClick={() => setIsOpen(true)}>{props.children}</span>
    </>
  )
}
