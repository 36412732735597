import { cn } from '@vectroid/shared/utils'
import { Subheading } from './heading'

export function Card({
  children,
  heading,
  className = '',
  contentClasses = '',
  actions,
}: {
  children: React.ReactNode
  heading?: string
  className?: string
  contentClasses?: string
  actions?: React.ReactNode
}) {
  const classes = cn(
    'relative h-full w-full rounded-xl bg-white p-4 shadow-[0px_0px_0px_1px_rgba(9,9,11,0.07),0px_2px_2px_0px_rgba(9,9,11,0.05)] dark:bg-zinc-950/80 dark:shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)] dark:before:pointer-events-none dark:before:absolute dark:before:-inset-px dark:before:rounded-xl dark:before:shadow-[0px_2px_8px_0px_rgba(0,_0,_0,_0.20),_0px_1px_0px_0px_rgba(255,_255,_255,_0.06)_inset] forced-colors:outline overflow-hidden',
    className
  )

  if (heading) {
    return (
      <div className={classes}>
        <div className="-m-4 mb-4 flex items-center border-b p-4 dark:border-zinc-700">
          <Subheading className="flex-1 select-none" level={3}>
            {heading}
          </Subheading>
          {actions && <div className="flex items-center">{actions}</div>}
        </div>

        <div className={contentClasses}>{children}</div>
      </div>
    )
  }

  return <div className={classes}>{children}</div>
}

export function DangerCard({
  children,
  heading,
  className = '',
  contentClasses = '',
  actions,
}: {
  children: React.ReactNode
  heading?: string
  className?: string
  contentClasses?: string
  actions?: React.ReactNode
}) {
  const classes = cn(
    'relative h-full w-full rounded-xl bg-white p-4 7) dark:bg-zinc-950/80 border border-red-900/40 dark:before:pointer-events-none dark:before:absolute dark:before:-inset-px dark:before:rounded-xl dark:before:shadow-[0px_2px_8px_0px_rgba(0,_0,_0,_0.20),_0px_1px_0px_0px_rgba(255,_255,_255,_0.06)_inset] forced-colors:outline overflow-hidden',
    className
  )

  if (heading) {
    return (
      <div className={classes}>
        <div className="-m-4 mb-4 flex items-center border-b p-4 dark:border-red-900/40">
          <Subheading className="flex-1 select-none" level={3}>
            {heading}
          </Subheading>
          {actions && <div className="flex items-center">{actions}</div>}
        </div>

        <div className={contentClasses}>{children}</div>
      </div>
    )
  }

  return <div className={classes}>{children}</div>
}
