import { Card } from 'components/catalyst/card'
import { Text } from 'components/catalyst/text'
import { ColumnDefinition, CommonTable } from 'components/common'
import { RouteNames } from '@vectroid/shared/const'
import { Index } from 'modules/indexes'
import { useDatasetDetailStore } from '../store/datase-detail.store'

export function DatasetIndexesTable() {
  const datasetDetailStore = useDatasetDetailStore()
  const { datasetIndexes } = datasetDetailStore

  const columns: ColumnDefinition<Index>[] = [
    { key: 'id', header: 'ID' },
    { key: 'name', header: 'Index Name', primary: true },
    { key: 'similarityFunction', header: 'Similarity Function' },
    { key: 'actions', header: 'Actions' },
  ]

  return (
    <Card heading="Indexes in this Dataset">
      <CommonTable<Index>
        columns={columns}
        data={datasetIndexes ?? []}
        setRowLink={(row) => RouteNames.IndexDetail.get(row.name)}
        emptyState={<Text>No indexes was found with this dataset.</Text>}
      />
    </Card>
  )
}
