import { Heading } from 'components/catalyst/heading'
import { Text } from 'components/catalyst/text'
import { AnimatePresence, motion } from 'framer-motion'
import { cn } from '@vectroid/shared/utils'
import { CheckIcon } from 'lucide-react'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'

export interface Step {
  id: number
  shortTitle: string
  title: string
  description: string
  content: React.FC<StepItemProps<any>> | React.ReactNode
  onStepFinish?: () => void
  optional?: boolean
  headerActions?: React.ReactNode
  stepActions?: React.ReactNode
}

export type StepItemProps<T> = {
  onPrevStep?: () => void
  onNextStep: (values?: T, form?: UseFormReturn<any>) => void
}

interface WizardProps {
  steps: Step[]
  currentStep: number
}

export const Wizard: React.FC<WizardProps> = ({ steps, currentStep }) => {
  const { content, title, description, headerActions = null } = steps[currentStep]

  return (
    <div className="flex items-start">
      <aside className="w-[240px] px-4">
        <ul className="pl-6">
          {steps.map((step, index) => {
            const active = index === currentStep
            const isDoneStep = index < currentStep
            const isLastStep = index === steps.length - 1

            return (
              <li key={index} className={cn('relative h-[100px] space-y-1.5', { 'opacity-60': index > currentStep })}>
                <Text className="!text-xs !font-medium">
                  Step {index + 1} {step.optional && <i>- Optional</i>}
                </Text>
                <span className={'relative block text-sm font-bold text-zinc-950 dark:text-white'}>
                  {!isLastStep && (
                    <span className={'dark:bg-white" absolute -left-5 top-4 h-[86px] w-[1px] bg-zinc-400'} />
                  )}
                  <span
                    className={cn({
                      'absolute -left-7 top-0.5 flex h-4 w-4 items-center justify-center rounded-full border-2 border-zinc-600 dark:border-white':
                        true,
                      'scale-[1.2] border-0 bg-green-600 dark:bg-green-500': isDoneStep,
                    })}
                  >
                    {active && <span className="h-1.5 w-1.5 rounded-full bg-sky-400" />}
                    {isDoneStep && <CheckIcon size={14} strokeWidth={3} className="text-white" />}
                  </span>
                  {step.shortTitle}
                </span>
                {step.stepActions}
              </li>
            )
          })}
        </ul>
      </aside>

      <div className="flex-1 space-y-4">
        <div className="max-w-2xl">
          <Heading level={3} description={description} actions={headerActions}>
            {title}
          </Heading>
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 12 }}
            exit={{ opacity: 0, y: -12 }}
            transition={{ duration: 0.25 }}
          >
            {content as React.ReactNode}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  )
}
