import { cn } from "@vectroid/shared/utils";
import { Card } from "components/catalyst/card";
import { Text } from "components/catalyst/text";
import { ArrowRightIcon } from "lucide-react";
import { Link } from "react-router-dom";

type Props = {
  label: string;
  href: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
  headerColor?: string;
};

export function DashboardRecentItem(props: Props) {
  return (
    <Link to={props.href} className="group flex-1 block col-span-4">
      <Card>
        <div
          className={cn(
            " -m-4 p-4 mb-4 flex items-center space-x-4",
            props.headerColor,
          )}
        >
          {props.icon}
          <Text className="flex-1 font-semibold text-zinc-900 transition-all group-hover:text-zinc-600 dark:text-zinc-300 group-hover:dark:text-zinc-400 sm:text-base">
            {props.label}
          </Text>
        </div>
        <div className="flex items-center">
          <div className="flex-1">{props.children}</div>
          <ArrowRightIcon size={16} />
        </div>
      </Card>
    </Link>
  );
}
