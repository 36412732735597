import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar'
import { CodeEditor } from 'components/code-editor'
import { CodeLanguageIcon } from 'components/code-lang-icon'
import { useUserDefaultsStore } from '@vectroid/shared/store'
import { CodeSnippetDropdown } from 'modules/api-playground/components/code-generator'
import { useEffect, useState } from 'react'
import {
  CodeSnippetLanguages,
  CodeSnippetTemplateIds,
  Snippet,
} from '@vectroid/shared/snippets'
import { useCodeGenerator } from '../hooks/use-code-generator'

type Props = {
  bodyObject: string
}

export function SdkClientPlayground(props: Props) {
  const preferredCodeLang = useUserDefaultsStore(
    (state) => state.preferredCodeLang,
  )
  const setPreferredCodeLang = useUserDefaultsStore(
    (state) => state.setPreferredCodeLang,
  )
  const selectedSnippet = CodeSnippetLanguages.find(
    (c) => c.id === preferredCodeLang,
  )

  const { generatedCode } = useCodeGenerator(props.bodyObject)

  function handleAdditionalTabClick(item: Snippet) {
    setPreferredCodeLang(item.id)
  }

  return (
    <>
      <Navbar>
        <NavbarSection>
          <NavbarItem current>
            <CodeLanguageIcon item={selectedSnippet!} className="-mr-2" />
            {selectedSnippet?.label}
          </NavbarItem>

          <CodeSnippetDropdown isTab handleItemClick={handleAdditionalTabClick}>
            Other Clients
          </CodeSnippetDropdown>
        </NavbarSection>
        <NavbarSpacer />
      </Navbar>

      <CodeEditor readonly code={generatedCode} />
    </>
  )
}
