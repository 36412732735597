import { Dropdown, DropdownButton, DropdownMenu } from 'components/catalyst/dropdown'
import { Bell } from 'lucide-react'
import { MockNotifications } from './mock-notifications'
import { Badge } from './catalyst/badge'

export function NotificationDropdown() {
  return (
    <Dropdown>
      <DropdownButton outline className="relative h-10 w-10">
        <Bell size={16} />
        <Badge
          className="absolute -right-2 -top-2 size-6 items-center justify-center !rounded-full !font-semibold"
          color="red"
        >
          5
        </Badge>
        <Badge
          className="absolute -right-2 -top-2 size-6 items-center justify-center !rounded-full !font-semibold"
          color="red"
        ></Badge>
      </DropdownButton>

      <DropdownMenu anchor="bottom end" className="!block">
        <MockNotifications />
      </DropdownMenu>
    </Dropdown>
  )
}
