import { GridLayout } from 'components/common/grid-layout/grid-layout'
import { DashboardLayout, useTheme } from 'context/theme-provider'
import { Index } from 'modules/indexes'
import { IndexItemGrid } from '../components/index-item-grid'
import { IndexListEmptyState } from '../components/index-list-empty-state'
import { IndexesStackLayout } from '../components/indexes-stack-layout'
import { useSearchParamFilteredArray } from 'hooks/use-search-query-param'

type Props = {
  loading: boolean
  indexes: Index[]
}

export function IndexListView(props: Props) {
  const { dashboardLayout } = useTheme()

  const indexes = useSearchParamFilteredArray(props.indexes, { searchKeys: ['name'] })

  if (!props.loading && props.indexes.length === 0) {
    return <IndexListEmptyState />
  }

  if (dashboardLayout === DashboardLayout.Grid) {
    return (
      <GridLayout loading={props.loading}>
        {indexes.map((index) => (
          <IndexItemGrid index={index} key={index.id} />
        ))}
      </GridLayout>
    )
  }

  return <IndexesStackLayout {...props} />
}
