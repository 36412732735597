import { cn } from '@vectroid/shared/utils'

function Skeleton<T extends React.ElementType = 'div'>({
  as,
  className,
  ...props
}: { as?: T } & React.ComponentPropsWithoutRef<T>) {
  const Component = as || 'div'
  return <Component className={cn('animate-pulse rounded-md bg-muted', className)} {...props} />
}

export { Skeleton }
