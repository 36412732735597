import { RouteNames } from '@vectroid/shared/const'

import AuthCallBackPage from './pages/auth-callback'
import ForgotPasswordPage from './pages/forgot-password'
import Login from './pages/login'
import RegisterPage from './pages/register'
import ResetPasswordPage from './pages/reset-password'
import VerifyAccountPage from './pages/verify-account'

const routes = [
  {
    path: RouteNames.Login,
    element: <Login />,
  },
  {
    path: RouteNames.Register,
    element: <RegisterPage />,
  },
  {
    path: RouteNames.ResetPassword,
    element: <ResetPasswordPage />,
  },
  {
    path: RouteNames.ForgotPassword,
    element: <ForgotPasswordPage />,
  },
  {
    path: RouteNames.VerifyAccount,
    element: <VerifyAccountPage />,
  },
  {
    path: RouteNames.GoogleSSOCallback,
    element: <AuthCallBackPage />,
  },
]

export default routes
