import { useAsyncData } from 'hooks/use-async-data'
import { Index } from 'modules/indexes'
import { useCallback } from 'react'
import { create } from 'zustand'
import { describeDatasetApi, listDatasetIndexesApi } from '../services'
import { Dataset } from '../types'

interface DatasetDetailState {
  dataset: Dataset | null
  datasetIndexes: Index[] | null
  setDataset: (dataset: Dataset | null) => void
  setDatasetIndexes: (indexes: Index[] | null) => void
}

export const useDatasetDetailStore = create<DatasetDetailState>((set) => ({
  dataset: null,
  datasetIndexes: null,
  setDataset: (dataset) => set({ dataset }),
  setDatasetIndexes: (indexes) => set({ datasetIndexes: indexes }),
}))

export function useDatasetDetail(datasetName: string) {
  const { dataset, datasetIndexes, setDataset, setDatasetIndexes } = useDatasetDetailStore()

  const { loading: datasetLoading, fetchData: fetchDatasets } = useAsyncData(describeDatasetApi, {
    onDataFetched: (fetchedDatasets) => setDataset(fetchedDatasets?.dataset),
    params: datasetName,
    showErrorMessage: true,
  })
  const { loading: datasetIndexesLoading, fetchData: fetchDatasetIndexes } = useAsyncData(listDatasetIndexesApi, {
    onDataFetched: (fetchedIndexes) => setDatasetIndexes(fetchedIndexes?.indexes),
    params: datasetName,
    showErrorMessage: true,
  })

  const fetchDatasetDetailPage = useCallback(() => {
    fetchDatasets()
    fetchDatasetIndexes()
  }, [])

  return { dataset, datasetLoading, datasetIndexesLoading, datasetIndexes, fetchDatasetIndexes, fetchDatasetDetailPage }
}
