import { apiClient } from '@vectroid/shared/api'

export function executePlaygroundCode(code: string, endpoint: string, method: string): Promise<any> {
  try {
    const parsedCode = code ? JSON.parse(code) : undefined
    return apiClient(endpoint, method, parsedCode)
  } catch (e) {
    console.log(e)
    return Promise.reject(e)
  }
}
