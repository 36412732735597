import { Divider } from 'components/catalyst/divider'
import { Text } from 'components/catalyst/text'
import { ArrowRightIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { cn } from '@vectroid/shared/utils'
import { RouteNames } from '@vectroid/shared/const'

const steps = [
  {
    number: 1,
    title: 'Create a dataset',
    href: RouteNames.CreateDataset,
    description: 'A dataset is the foundation for indexing and querying vectors.',
  },
  {
    number: 2,
    title: 'Add data into your dataset',
    description: 'Add your vectors into the created dataset to prepare for indexing.',
  },
  {
    number: 3,
    title: 'Index your dataset',
    href: RouteNames.CreateIndex,
    description: 'This step transforms your raw vectors into a queryable format optimized for performance.',
  },
  {
    number: 4,
    title: 'Query',
    href: `${RouteNames.ApiPlayground}?type=search_vector`,
    description: 'Run queries on your indexed vectors to discover similarities and insights.',
  },
]

export function DashboardEmptyState() {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col">
      {steps.map((step, index) => (
        <div key={step.number}>
          <div
            className={cn(
              "group cursor-pointer rounded-xl p-4 py-6 transition-all hover:bg-zinc-200/50 dark:border-zinc-800 hover:dark:bg-zinc-900/60 mb-4",
              !step.href &&  "pointer-events-none"
            )}
            role="button"
            onClick={() => step.href && navigate(step.href)}
          >
            <div className="flex items-center">
              <span
                className={
                  'mr-4 flex size-12 items-center justify-center rounded-full bg-zinc-200 font-semibold transition-all dark:bg-zinc-800'
                }
              >
                {step.number}
              </span>
              <h3 className="flex-1">
                <span className="font-bold">{step.title}</span>
                <Text>{step.description}</Text>
              </h3>
              <ArrowRightIcon className="ml-4 size-6 self-center text-zinc-500/50" />
            </div>
          </div>
          {index !== steps.length - 1 && <Divider className='mb-4' />}
        </div>
      ))}
    </div>
  )
}
