import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Input } from './catalyst/input'

export function SearchInput() {
  const [search, setSearch] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <Input
      value={search}
      onChange={(e) => {
        setSearch(e.target.value)
        searchParams.set('search', e.target.value)
        setSearchParams(searchParams)
      }}
      name="search"
      placeholder="Search&hellip;"
      aria-label="Search..."
      className="flex-[5]"
    />
  )
}
