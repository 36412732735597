export enum WindowEventTypes {
  Auth = 'auth',
}

export interface WindowEventPayload<T> {
  type: WindowEventTypes
  success: boolean
  payload: T
}

export function postWindowEvent<T>(type: WindowEventTypes, payload: T, success: boolean) {
  window.opener.postMessage({ type, success, payload }, '*')
  window.close()
}
