import { Button } from 'components/catalyst/button'
import { Divider } from 'components/catalyst/divider'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar'
import { Text } from 'components/catalyst/text'
import { RouteNames } from '@vectroid/shared/const'
import { ExternalLink } from 'lucide-react'
import { SdkClientPlayground } from 'modules/api-playground/components/sdk-client-playground'
import { CodeSnippetTemplateIds } from '@vectroid/shared/snippets'
import { useState } from 'react'

const CREATE_INDEX_BODY_OBJECT = `{
  "indexName": "<YOUR_INDEX_NAME>",
  "similarityFunction": "cosine",
  "cloud": "gcp",
  "region": "us-east1"
}`

enum Tabs {
  Editor,
  Api,
  ApiPlayground,
}

export function QueryVectorsStep() {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Editor)
  const tabs = [
    { id: Tabs.Editor, label: 'Using the Playground ' },
    { id: Tabs.Api, label: 'Through the API' },
  ]

  return (
    <div className="!-mt-6 max-w-2xl">
      <Navbar>
        <NavbarSection className="w-full">
          {tabs?.map((tab, index) => (
            <NavbarItem
              onClick={() => setSelectedTab(tab.id)}
              current={tab.id === selectedTab}
              key={tab.label}
              href="#"
              className="flex-1"
            >
              <div className="flex flex-col">
                <Text className="mb-1.5 !text-xs">Option {index + 1}</Text>
                {tab.label}
              </div>
            </NavbarItem>
          ))}
        </NavbarSection>
        <NavbarSpacer />
      </Navbar>
      <Divider className="mb-6" soft />

      {selectedTab === Tabs.Editor && (
        <>
          <Button
            href={`${RouteNames.ApiPlayground}?type=search_vector`}
            color="amber"
          >
            Start Querying on the Playground{' '}
            <ExternalLink size={16} strokeWidth={2.5} />
          </Button>
        </>
      )}

      {selectedTab === Tabs.Api && (
        <div className="-mt-4">
          <SdkClientPlayground bodyObject={CREATE_INDEX_BODY_OBJECT} />
        </div>
      )}
    </div>
  )
}
