import { z } from 'zod'
import { CloudProvider, Region } from '../const'

export const DatasetCreateSchema = z.object({
  name: z
    .string()
    .min(2, { message: 'Name must be at least 2 characters long' })
    .max(50, { message: 'Name cannot exceed 50 characters' })
    .regex(/^[A-Za-z0-9-]+$/, {
      message: 'Name can only contain English letters, numbers, and dashes, with no spaces',
    }),
  dimensions: z.string().min(1).max(4).regex(/[0-9]/, { message: 'Must be a number' }),
  cloud: z.enum([CloudProvider.GCP]),
  region: z.enum([Region.US_EAST]),
})
