import { Listbox, ListboxOption } from 'components/catalyst/listbox'
import { PUBLIC_DATASETS } from 'modules/datasets/const/public-datasets'
import { useDatasetStore } from 'modules/datasets/store/dataset.store'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Field, FieldTypes } from '../types'
import { SelectField } from './select.field'

type Props = {
  formField: Field & { fieldType: FieldTypes.DatasetSelect }
  field: any
  loading?: boolean
}

enum DatasetSelectType {
  MyDatasets = 'my-datasets',
  SharedWithMe = 'shared-with-me',
  PublicDatasets = 'public-datasets',
}

export function DatasetSelectField({ field, formField, ...props }: Props) {
  const { datasets, loading, fetchDatasets } = useDatasetStore()
  const [searchParams] = useSearchParams()

  const [selectType, setSelectType] = useState<DatasetSelectType>(DatasetSelectType.MyDatasets)

  const getOptions = () => {
    switch (selectType) {
      case DatasetSelectType.MyDatasets:
        return datasets.map((dataset) => ({ label: dataset.name, value: dataset.name }))
      case DatasetSelectType.SharedWithMe:
        return []
      case DatasetSelectType.PublicDatasets:
        return PUBLIC_DATASETS.map((dataset) => ({ label: dataset.name, value: dataset.name }))
      default:
        return []
    }
  }

  const typeSelectOptions = [
    { label: 'My Datasets', value: DatasetSelectType.MyDatasets },
    { label: 'Shared With Me', value: DatasetSelectType.SharedWithMe },
    { label: 'Public Datasets', value: DatasetSelectType.PublicDatasets },
  ]

  useEffect(() => {
    field.onChange('')
  }, [selectType])

  useEffect(() => {
    fetchDatasets()
  }, [])

  useEffect(() => {
    const dataset = searchParams.get('dataset')
    if (dataset && datasets.some((d) => d.name === dataset)) {
      field.onChange(dataset)
    }
  }, [searchParams, datasets])

  return (
    <div className="grid grid-cols-12 space-x-4">
      <Listbox
        defaultValue={selectType}
        className="col-span-4 !rounded-r-none"
        onChange={(value) => setSelectType(value)}
      >
        {typeSelectOptions.map((option) => (
          <ListboxOption key={option.value} value={option.value}>
            {option.label}
          </ListboxOption>
        ))}
      </Listbox>

      <div className="col-span-8">
        <SelectField
          formField={{
            ...formField,
            fieldType: FieldTypes.Select,
            options: getOptions(),
          }}
          field={field}
          loading={loading}
        />
      </div>
    </div>
  )
}
