import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { Step, Wizard } from 'components/common/wizard'
import { ArrowRight, Braces } from 'lucide-react'
import { useDatasetCreateStore, useDatasetCreateWizardStore } from '../store/dataset-create.store'
import { DatasetCreateSteps } from '../const'

// Steps
import { Text } from 'components/catalyst/text'
import { RouteNames } from '@vectroid/shared/const'
import { CodeGenerator } from 'modules/api-playground/components/code-generator'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CreateDatasetFormStep } from '../components/steps/create-dataset-form.step'
import { CreateIndexStepForm } from '../components/steps/create-index-form.step'
import { InsertDataStep } from '../components/steps/insert-data.step'
import { QueryVectorsStep } from '../components/steps/query-vectors.step'

const wizardSteps: Step[] = [
  {
    id: DatasetCreateSteps.DATASET_CREATE,
    shortTitle: 'Create Dataset',
    title: 'Create a new dataset',
    description: 'Configure your dataset, then index with different configurations.',
    content: <CreateDatasetFormStep />,
    headerActions: (
      <CodeGenerator bodyObject="{}">
        <Button outline>
          <Braces size={14} /> Code Equivalent
        </Button>
      </CodeGenerator>
    ),
  },
  {
    id: DatasetCreateSteps.INSERT_DATA,
    shortTitle: 'Insert Data',
    title: 'Insert vectors into your dataset',
    description: 'Add data to your dataset, enabling you to index it later with various configurations.',
    content: <InsertDataStep />,
    optional: true,
  },
  {
    id: DatasetCreateSteps.CREATE_INDEX,
    shortTitle: 'Create Index',
    title: 'Create Index',
    description: 'Create an index to query your dataset.',
    content: <CreateIndexStepForm />,
    optional: true,
    headerActions: (
      <Button outline>
        <Braces size={14} /> Code Equivalent
      </Button>
    ),
  },
  {
    id: DatasetCreateSteps.QUERY,
    shortTitle: 'Query',
    title: 'Query',
    description: 'Query your index',
    content: <QueryVectorsStep />,
    optional: true,
  },
]

function CreateDatasetPage() {
  const { currentStep } = useDatasetCreateWizardStore()
  const [steps, setSteps] = useState<Step[]>(wizardSteps)

  const { dataset, index } = useDatasetCreateStore()

  function handleAddStepAction(stepId: number, label: string, route: string) {
    const newSteps = [...steps]
    newSteps[stepId].stepActions = (
      <Link to={route} className="mt-1 block">
        <Text className="flex items-center space-x-1 !text-xs !font-semibold !text-emerald-500 hover:underline">
          <span>{label}</span> <ArrowRight size={14} />
        </Text>
      </Link>
    )
    setSteps(newSteps)
  }

  useEffect(() => {
    if (dataset) {
      handleAddStepAction(
        DatasetCreateSteps.DATASET_CREATE,
        'Go to Dataset',
        RouteNames.DatasetDetail.get(dataset.name)
      )
    }
  }, [steps, dataset])

  useEffect(() => {
    if (index) {
      handleAddStepAction(DatasetCreateSteps.CREATE_INDEX, 'Go to Index', RouteNames.IndexDetail.get(index.name))
    }
  }, [steps, index])

  return (
    <>
      <Heading level={2} description="Create a dataset, add your data, and start indexing and querying." pageHeading>
        New Dataset
      </Heading>

      <Wizard steps={steps} currentStep={currentStep} />
    </>
  )
}

export default CreateDatasetPage
