import { RouteObject } from 'react-router-dom'

import { RouteNames } from '@vectroid/shared/const'
import DatasetsPage from './pages'
import CreateDatasetPage from './pages/create'
import { DatasetDetailPage } from './pages/detail'

const routes: RouteObject[] = [
  {
    path: RouteNames.Datasets,
    element: <DatasetsPage />,
  },
  {
    path: RouteNames.CreateDataset,
    element: <CreateDatasetPage />,
  },
  {
    path: RouteNames.DatasetDetail.name,
    element: <DatasetDetailPage />,
  },
]

export default routes
