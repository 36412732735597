import { Heading } from 'components/catalyst/heading'
import { ApiKeyCreateDialog } from '../components/api-key-create-dialog'
import { ApiKeysTable } from '../components/api-keys-table'

function ApiKeysPage() {
  return (
    <>
      <Heading
        level={2}
        className="sm:text-lg/8"
        description="Manage your API-keys and access to your index."
        pageHeading
        actions={
          <>
            <ApiKeyCreateDialog />
          </>
        }
      >
        API Keys
      </Heading>

      <ApiKeysTable />
    </>
  )
}

export default ApiKeysPage
