import { cn } from '@vectroid/shared/utils'
import { Button } from './catalyst/button'
import { Divider } from './catalyst/divider'

const activity = [
  {
    id: 1,
    type: 'created',
    date: '7d ago',
    dateTime: '2023-01-23T10:32',
    dataset: 'Dataset A',
  },
  {
    id: 2,
    type: 'provisioning',
    date: '6d ago',
    dateTime: '2023-01-23T11:03',
    dataset: 'Index A',
  },
  {
    id: 3,
    type: 'ready',
    date: '6d ago',
    dateTime: '2023-01-23T11:24',
    dataset: 'Index A',
  },
  {
    id: 4,
    type: 'indexing',
    date: '2d ago',
    dateTime: '2023-01-24T09:12',
    dataset: 'Dataset B',
  },
  {
    id: 5,
    type: 'indexed',
    date: '1d ago',
    dateTime: '2023-01-24T09:20',
    dataset: 'Dataset B',
  },
]

export function MockNotifications() {
  return (
    <div className="w-full">
      <ul className="w-full space-y-6 p-4">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-1">
            <div
              className={cn(
                activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6',
                'absolute left-0 top-0 -ml-3 flex w-6 justify-center'
              )}
            >
              <div className="w-px bg-zinc-200 dark:opacity-70" />
            </div>
            <>
              <div className="relative -ml-3 flex size-6 flex-none items-center justify-center rounded-full">
                <div className="size-1.5 rounded-full bg-zinc-100 ring-1 ring-zinc-300 dark:opacity-70" />
              </div>
              <p className="min-w-[200px] flex-auto py-0.5 text-xs/5 text-zinc-500">
                <span className="font-medium text-zinc-900 dark:text-white">{activityItem.dataset}</span> was{' '}
                {activityItem.type}.
              </p>
              <time dateTime={activityItem.dateTime} className="flex-none py-0.5 text-xs/5 text-zinc-500">
                {activityItem.date}
              </time>
            </>
          </li>
        ))}
      </ul>

      <Divider soft />

      <Button plain to={'#'} className={'w-full'}>
        See all notifications
      </Button>
    </div>
  )
}
