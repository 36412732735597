import { CollectionType, Collection } from './collection-types'
import DatasetCollections from './dataset.collections'
import IndexCollections from './index.collections'

export * from './collection-types'
export * from './utils'

export const API_COLLECTIONS: { [key in CollectionType]: Collection } = {
  ...DatasetCollections,
  ...IndexCollections,
}

export const GLOBAL_API_PLAYGROUND_COLLECTION: { title: string; items: Collection[] }[] = [
  {
    title: 'Control Plane',
    items: [
      API_COLLECTIONS[CollectionType.CREATE_DATASET],
      API_COLLECTIONS[CollectionType.LIST_DATASETS],
      API_COLLECTIONS[CollectionType.DATASET_INSERT],
      API_COLLECTIONS[CollectionType.DESCRIBE_DATASET],
    ],
  },

  {
    title: 'Vector Operations',
    items: [
      API_COLLECTIONS[CollectionType.CREATE_INDEX],
      API_COLLECTIONS[CollectionType.DESCRIBE_INDEX],
      API_COLLECTIONS[CollectionType.LIST_INDEXES],
      API_COLLECTIONS[CollectionType.SEARCH_VECTOR],
    ],
  },
]

export const INDEX_API_PLAYGROUND_COLLECTION: { title: string; items: Collection[] }[] = [
  {
    title: 'Index Operations',
    items: [API_COLLECTIONS[CollectionType.DESCRIBE_INDEX], API_COLLECTIONS[CollectionType.DROP_INDEX]],
  },

  {
    title: 'Vector Operations',
    items: [API_COLLECTIONS[CollectionType.SEARCH_VECTOR]],
  },
]
