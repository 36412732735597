import { CheckIcon, CopyIcon } from 'lucide-react'
import { useState } from 'react'
import { Button } from './catalyst/button'

type Props = {
  text: string
  className?: string
  color?: string
  iconOnly?: boolean
}

export function CopyButton({ text, color, iconOnly = false, ...props }: Props) {
  const [btnText, setBtnText] = useState('Copy')

  function handleCopy() {
    navigator?.clipboard?.writeText(text)

    setBtnText('Copied!')
    setTimeout(() => {
      setBtnText('Copy')
    }, 2000)
  }

  return (
    <Button onClick={handleCopy} {...(color ? { color: color as any } : { outline: true })} className={props.className}>
      {iconOnly ? (
        <>{btnText === 'Copied!' ? <CheckIcon size={16} /> : <CopyIcon size={16} />}</>
      ) : (
        <CopyIcon size={16} />
      )}
      {!iconOnly && btnText}
    </Button>
  )
}
