import { useWindowSize } from 'hooks/use-window-size'
import { createContext, useContext, useEffect, useState } from 'react'

export type Theme = 'dark' | 'light' | 'system'

type ThemeProviderProps = {
  children: React.ReactNode
  defaultTheme?: Theme
  storageKey?: string
}

type ThemeProviderState = {
  theme: Theme
  setTheme: (theme: Theme) => void
  sidebarExpanded: boolean
  setSidebarExpanded: (value: boolean) => void
  dashboardLayout: DashboardLayout
  setDashboardLayout: (layout: DashboardLayout) => void
}

export enum DashboardLayout {
  Stacked = 'stacked',
  Grid = 'grid',
}

const initialState: ThemeProviderState = {
  theme: 'system',
  sidebarExpanded: true,
  setSidebarExpanded: (value: boolean) => null,
  setTheme: () => null,
  dashboardLayout: DashboardLayout.Stacked,
  setDashboardLayout: () => null,
}

const ThemeProviderContext = createContext<ThemeProviderState>(initialState)

export function ThemeProvider({
  children,
  defaultTheme = 'system',
  storageKey = 'vite-ui-theme',
  ...props
}: ThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>(() => (localStorage.getItem(storageKey) as Theme) || defaultTheme)
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(true)
  const { width: windowWidth } = useWindowSize()
  const [dashboardLayout, setDashboardLayout] = useState<DashboardLayout>(
    () => (localStorage.getItem('dashboard-layout') as unknown as DashboardLayout) || DashboardLayout.Stacked
  )

  useEffect(() => {
    const root = window.document.documentElement

    root.classList.remove('light', 'dark')

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

      root.classList.add(systemTheme)
      return
    }

    root.classList.add(theme)
  }, [theme])

  useEffect(() => {
    if (windowWidth && windowWidth < 1024) {
      setSidebarExpanded(true)
    }
  }, [windowWidth])

  const value = {
    theme,
    sidebarExpanded,
    setSidebarExpanded: (value: boolean) => {
      setSidebarExpanded(value)
    },
    setTheme: (theme: Theme) => {
      localStorage.setItem(storageKey, theme)
      setTheme(theme)
    },
    dashboardLayout,
    setDashboardLayout: (layout: DashboardLayout) => {
      localStorage.setItem('dashboard-layout', layout)
      setDashboardLayout(layout)
    },
  }

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext)

  if (context === undefined) throw new Error('useTheme must be used within a ThemeProvider')

  return context
}
