import { Badge } from 'components/catalyst/badge'
import { Button } from 'components/catalyst/button'
import { Card } from 'components/catalyst/card'
import { Divider } from 'components/catalyst/divider'
import { CodeEditor } from 'components/code-editor'
import { Play, Sparkles } from 'lucide-react'
import { useState } from 'react'
import { insertDataApi } from '../services'
import { useDatasetDetailStore } from '../store/datase-detail.store'
import { generateMockVectors, getRandomInt } from '../utils'

export function DatasetInsertEditor() {
  const dataset = useDatasetDetailStore((state) => state.dataset)
  const mockVectors = generateMockVectors(5, dataset?.dimensions!)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [code, setCode] = useState(mockVectors)

  function onChange(value: string) {
    setCode(value)
  }

  async function onInsert() {
    try {
      setLoading(true)
      const parsed = JSON.parse(code)
      await Promise.all(
        parsed.map((item: any) =>
          insertDataApi({
            datasetName: dataset?.name!,
            data: item,
          })
        )
      )

      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card
      heading="Insert Vectors"
      contentClasses="flex flex-col h-[500px]"
      actions={
        <Button plain onClick={() => setCode(generateMockVectors(getRandomInt(5, 100), dataset?.dimensions!))}>
          <Sparkles size={16} strokeWidth={2.5} />
        </Button>
      }
    >
      <div className="h-[450px] flex-1 overflow-y-scroll">
        <CodeEditor code={code} onChange={onChange} containerComponent={'div'} />
      </div>
      <Divider />
      <div className="mt-4 flex justify-end space-x-4">
        {success && (
          <Badge className="px-2" color="green">
            Success!
          </Badge>
        )}
        <Button loading={loading} className="inline-flex" color="amber" onClick={onInsert}>
          <Play size={16} strokeWidth={2.5} /> Insert
        </Button>
      </div>
    </Card>
  )
}
