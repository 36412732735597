import { Button } from 'components/catalyst/button'
import { ColumnDefinition, CommonTable } from 'components/common'
import { useSearchParamFilteredArray } from 'hooks/use-search-query-param'
import { PUBLIC_DATASETS } from '../const/public-datasets'
import { formatNumber } from '@vectroid/shared/utils'
import { Text } from 'components/catalyst/text'
import { PublicDataset } from '../types'

export function PublicDatasets() {
  const items = useSearchParamFilteredArray<PublicDataset>(PUBLIC_DATASETS, { searchKeys: ['name'] })

  const columns: ColumnDefinition<PublicDataset>[] = [
    { key: 'id', header: 'ID' },
    {
      key: 'name',
      header: 'Name',
      primary: true,
    },
    { key: 'distance', header: 'Distance' },
    { key: 'dimensions', header: 'Dimensions' },
    { key: 'trainSize', header: 'Train Size', cell: (row) => <Text>{formatNumber(row.trainSize)}</Text> },
    { key: 'testSize', header: 'Test Size', cell: (row) => <Text>{formatNumber(row.testSize)}</Text> },
    { key: 'neighbors', header: 'Neighbors' },
    {
      key: 'actions',
      header: 'Actions',
      cell: (row) => (
        <Button className="!px-2 !py-0.5" color="teal" onClick={() => console.log('Indexing', row.name)}>
          Index this dataset
        </Button>
      ),
    },
  ]

  return (
    <CommonTable<PublicDataset>
      columns={columns}
      data={items}
      setRowLink={() => '#'}
      onRowClick={() => {
        console.log('Row clicked')
      }}
    />
  )
}
