import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownMenu } from 'components/catalyst/dropdown'
import { useTheme } from 'context/theme-provider'
import { CheckIcon, MoonIcon, SunIcon } from 'lucide-react'

export function ThemeToggle() {
  const { setTheme, theme } = useTheme()

  return (
    <Dropdown>
      <DropdownButton outline className="h-10 w-10">
        <MoonIcon size={16} className="hidden dark:block" />
        <SunIcon size={16} className="block dark:hidden" />
      </DropdownButton>

      <DropdownMenu>
        <DropdownItem className="!flex min-w-32" onClick={() => setTheme('light')}>
          <span className="flex-1">Light</span> {theme === 'light' && <CheckIcon size={16} />}
        </DropdownItem>
        <DropdownItem className="!flex min-w-32" onClick={() => setTheme('dark')}>
          <span className="flex-1">Dark</span> {theme === 'dark' && <CheckIcon size={16} />}
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem className="!flex min-w-32" onClick={() => setTheme('system')}>
          <span className="flex-1">System</span> {theme === 'system' && <CheckIcon size={16} />}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
