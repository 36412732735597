import { Button } from 'components/catalyst/button'
import { Input } from 'components/catalyst/input'
import { CopyButton } from 'components/copy-button'
import { appConfig } from '@vectroid/shared/config'

export function InviteViaLink(props: { onDone: () => void }) {
  const link = `${appConfig.CONSOLE_BASE_URL}/datasets/invite/YBcqFojoO7XUqA5BWsZzRPDCpRFe91n9`

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-2">
        <Input type="text" readOnly value={link} className="select-all" />
        <CopyButton iconOnly color="zinc" text={link} className="!py-2" />
      </div>
      <Button outline>Reset Link</Button>
    </div>
  )
}
