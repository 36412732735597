import { z } from 'zod'

import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { useNotificationStore } from 'components/common'
import { Form } from 'components/common/form/form'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { RouteNames } from '@vectroid/shared/const'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ResetPasswordFormSchema } from '../schema/reset-password.schema'
import { setupNewPassword, useAuthStore } from '@vectroid/shared/auth'

const formSchema = ResetPasswordFormSchema

enum FieldNames {
  Password = 'password',
  PasswordConfirmation = 'passwordConfirmation',
}

type FormSchema = z.infer<typeof formSchema>

const formFields = [
  { name: FieldNames.Password, label: 'Password', type: 'password', autoFocus: true },
  { name: FieldNames.PasswordConfirmation, label: 'Confirm Password', type: 'password' },
]

function ResetPasswordPage() {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()

  const loginAction = useAuthStore((state) => state.login)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const resetToken = searchParams.get('token')

  const defaultValues: FormSchema = {
    password: '',
    passwordConfirmation: '',
  }

  async function onSubmit(values: FormSchema) {
    try {
      setLoading(true)

      if (values.password !== values.passwordConfirmation) {
        setMessage('Passwords do not match', { type: 'error' })
        return
      }

      if (!resetToken) {
        setMessage('Invalid token', { type: 'error' })
        return
      }

      const response = await setupNewPassword({
        password: values.password,
        token: resetToken,
      })
      loginAction(response.data)
      navigate(RouteNames.Dashboard)
    } catch (e: any) {
      console.log(e)
      setMessage(e?.message ?? GENERIC_ERROR_MESSAGE, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Heading level={1}>Reset Password</Heading>

      <Form
        formFields={formFields}
        formSchema={formSchema}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        loading={loading}
        actionBtnText="Reset Password & Login"
        actionBtnProps={{ color: 'zinc', className: 'w-full' }}
      />

      <Button outline href={RouteNames.Login}>
        Back to Login
      </Button>
    </>
  )
}

export default ResetPasswordPage
