import { z } from 'zod'

import { useNotificationStore } from 'components/common'
import { Form } from 'components/common/form/form'
import { Field, FieldTypes } from 'components/common/form/types'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { createDatasetApi } from 'modules/datasets/services'
import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { DatasetCreateSchema } from '../../schema/dataset-create.schema'
import { CloudProvider, Region } from 'modules/datasets/const'
import { useNavigate } from 'react-router-dom'
import { RouteNames } from '@vectroid/shared/const'

const formSchema = DatasetCreateSchema

export type FormSchema = z.infer<typeof formSchema>

enum FieldNames {
  name = 'name',
  dimensions = 'dimensions',
  cloud = 'cloud',
  region = 'region',
}

const formFields: Field[] = [
  { name: FieldNames.name, label: 'Dataset Name', type: FieldTypes.Text, autoFocus: true },
  { name: FieldNames.dimensions, fieldType: FieldTypes.Text, label: 'Dimension', placeholder: '512' },
  {
    name: FieldNames.cloud,
    fieldType: FieldTypes.Select,
    options: [{ label: 'GCP', value: CloudProvider.GCP }],
    label: 'Cloud',
  },
  {
    name: FieldNames.region,
    fieldType: FieldTypes.Select,
    options: [{ label: 'us-east1', value: Region.US_EAST }],
    label: 'Region',
  },
]

const defaultValues: FormSchema = {
  [FieldNames.name]: '',
  [FieldNames.dimensions]: '',
  [FieldNames.cloud]: CloudProvider.GCP,
  [FieldNames.region]: Region.US_EAST,
}

type Props = {}

export function CreateDatasetFormStep({}: Props) {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()
  const navigate = useNavigate()

  async function handleSubmit(values: FormSchema, form: UseFormReturn<any>) {
    try {
      setLoading(true)
      const dataset = {
        ...values,
        dimensions: Number(values.dimensions),
      }

      const response = await createDatasetApi(dataset)
      if (response.data.id) {
        form.reset()
        setMessage('Dataset created successfully', { type: 'success' })
        navigate(RouteNames.Datasets)
      }
    } catch (error: any) {
      setMessage(error?.message || GENERIC_ERROR_MESSAGE, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      formSchema={formSchema}
      formFields={formFields}
      defaultValues={defaultValues}
      loading={loading}
      actionBtnText="Create & Continue"
    />
  )
}
