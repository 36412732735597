import { AnchorProps } from '@headlessui/react/dist/internal/floating'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
} from 'components/catalyst/dropdown'
import { EllipsisVertical } from 'lucide-react'
import React from 'react'

type MenuItem = {
  key: string
  label: string | React.ReactNode
  onClick?: () => void
  as?: React.ElementType
  href?: string
  render?: () => React.ReactNode
  disabled?: boolean
}

interface CommonDropdownProps {
  buttonLabel?: React.ReactNode | string
  menuItems: MenuItem[]
  header?: string
  className?: string
  children?: React.ReactNode
  anchor?: AnchorProps
}

export function CommonDropdown({ anchor, buttonLabel, children, menuItems, header, className }: CommonDropdownProps) {
  return (
    <Dropdown>
      {/* Dropdown Button */}
      {children ? (
        children
      ) : (
        <DropdownButton plain className={className}>
          {buttonLabel ? buttonLabel : <EllipsisVertical size={16} />}
        </DropdownButton>
      )}

      {/* Dropdown Menu */}
      <DropdownMenu anchor={anchor}>
        {header && <DropdownHeader>{header}</DropdownHeader>}

        {menuItems.map((item, index) => (
          <React.Fragment key={item.key}>
            {/* Divider Support */}
            {item.key === 'divider' ? (
              <DropdownDivider />
            ) : (
              // @ts-ignore
              <DropdownItem as={item.as} href={item.href ?? '#'} onClick={item.onClick} data-disabled={item.disabled}>
                {item.render ? item.render() : item.label}
              </DropdownItem>
            )}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
