import { useNotificationStore } from 'components/common'
import { API_COLLECTIONS, CollectionType } from '@vectroid/shared/api-collections'

import { QueryConsole } from 'modules/api-playground/components/query-console'
import { useIndexStore } from 'modules/indexes/store'

import { useCollectionStore } from 'modules/api-playground/store/collection.store'
import { executePlaygroundCode } from 'modules/api-playground/utils/playground-helper'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { generateMockVectors } from 'modules/datasets/utils'

function ConsolePage() {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<any>(null)
  const setMessage = useNotificationStore((state) => state.setMessage)

  const { activeCollection, setActiveCollection } = useCollectionStore()

  const params = useParams()

  const { getIndexByName } = useIndexStore()
  const indexDetail = getIndexByName(params.indexName!)

  function setResponse(data: any) {
    setResult(JSON.stringify(data, null, 3))
  }

  const onExecute = async (httpUrl: string, code: string) => {
    try {
      setLoading(true)

      const response = await executePlaygroundCode(code, httpUrl, activeCollection.method)
      response && setResponse(response.data as unknown as any)
    } catch (e: any) {
      console.log(e)
      setResponse(e)
      setMessage(e.message, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const newCollection = API_COLLECTIONS[CollectionType.SEARCH_VECTOR]
    newCollection.snippets!.json = `{
  "indexName": "{{indexName}}",
  "vector": [${JSON.parse(generateMockVectors(1, 3))[0].vector}],
  "k": 10
}`
    setActiveCollection(newCollection)
  }, [])

  return (
    <div className="flex h-full flex-1">
      <QueryConsole
        activeCollection={activeCollection}
        dynamicVariables={{ indexName: indexDetail?.name, indexId: indexDetail?.id }}
        onExecute={onExecute}
        loading={loading}
        result={result}
      />
    </div>
  )
}

export default ConsolePage
