import { zodResolver } from '@hookform/resolvers/zod'
import { ControllerRenderProps, useForm, UseFormReturn } from 'react-hook-form'
import { z } from 'zod'

import { Banner } from 'components/banner'
import { Button } from 'components/catalyst/button'
import { Divider } from 'components/catalyst/divider'
import { useNotificationStore } from 'components/common'
import { Form as UIForm, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { SelectField } from './fields/select.field'
import { Field, FieldTypes } from './types'
import { DatasetSelectField } from './fields/dataset-select.field'
import { TextField } from './fields/text.field'
import { Text } from 'components/catalyst/text'

interface Props {
  formSchema: any
  formFields: Field[]
  onSubmit?: (values: any, form: UseFormReturn<any>) => void
  defaultValues: Record<string, any>
  loading?: boolean
  actionBtnText?: string
  cancelBtnText?: string
  onCancel?: () => void
  inlineErrorMessages?: boolean
  actionBtnProps?: any
  customActions?: { label: string; onClick: () => void }[]
}

export function getInputElement(formField: Field, field: ControllerRenderProps<any>, loading?: boolean) {
  switch (formField.fieldType) {
    case FieldTypes.Select:
      return <SelectField formField={formField} field={field} />

    case FieldTypes.DatasetSelect:
      return <DatasetSelectField formField={formField} field={field} />
  }

  return <TextField formField={formField} field={field} loading={loading} />
}

export function Form(props: Props) {
  const messageProps = useNotificationStore()

  const form = useForm<z.infer<typeof props.formSchema>>({
    resolver: zodResolver(props.formSchema),
    defaultValues: props.defaultValues,
  })


  function renderForm() {
    return props.formFields.map((formField) => (
      <FormField
        key={formField.name}
        control={form.control}
        name={formField.name as any}
        render={({ field }) => {
          const inputElement = getInputElement(formField, field, props.loading)
          const hint = formField?.renderHint?.(field.value)

          return (
            <FormItem>
              <FormLabel className="!select-text" dangerouslySetInnerHTML={{ __html: formField.label }} />

              <FormControl>
                <>
                  {inputElement}
                  {!!hint && <Text dangerouslySetInnerHTML={{ __html: hint }}></Text>}
                </>
              </FormControl>

              <FormMessage className="text-xs" />
            </FormItem>
          )
        }}
      />
    ))
  }

  function renderCustomActions() {
    return props.customActions?.map((action) => (
      <Button key={action.label} onClick={action.onClick} outline>
        {action.label}
      </Button>
    ))
  }

  const isLoading = props.loading || form.formState.isSubmitting

  return (
    <UIForm {...form}>
      <form
        onSubmit={form.handleSubmit((values) => props?.onSubmit?.(values, form))}
        className="w-full space-y-6 sm:max-w-md"
      >
        {renderForm()}

        {props.inlineErrorMessages && messageProps.visible && (
          <Banner title={messageProps.title} type={messageProps.type} message={messageProps.message} />
        )}

        <Divider soft />

        <div className="flex justify-end space-x-4">
          {props.onCancel && (
            <Button plain onClick={props.onCancel}>
              {props.cancelBtnText ?? 'Cancel'}
            </Button>
          )}
          {renderCustomActions()}
          <Button color="amber" type="submit" loading={isLoading} disabled={isLoading} {...props.actionBtnProps}>
            {props.actionBtnText ?? 'Submit'}
          </Button>
        </div>
      </form>
    </UIForm>
  )
}
