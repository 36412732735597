import { apiClient } from '../api/api-client'
import {
  CheckEmailRequest,
  CheckEmailResponse,
  CreateAccountRequest,
  CreateAccountResponse,
  GoogleSSOLoginRequest,
  LoginRequest,
  LoginResponse,
  RefreshTokenRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SetupNewPasswordRequest,
  VerifyAccountRequest,
} from './types'

export function createAccountApi(values: CreateAccountRequest) {
  return apiClient<CreateAccountRequest, CreateAccountResponse>(
    '/account/create',
    'POST',
    values,
  )
}

export function loginWithGoogleSSO(values: GoogleSSOLoginRequest) {
  return apiClient<GoogleSSOLoginRequest, LoginResponse>(
    '/sso/google',
    'POST',
    values,
  )
}

export function checkEmailApi(values: CheckEmailRequest) {
  return apiClient<CheckEmailRequest, CheckEmailResponse>(
    '/account/email/check',
    'POST',
    values,
  )
}

export function loginApi(values: LoginRequest) {
  return apiClient<LoginRequest, LoginResponse>('/login', 'POST', values)
}

export function refreshTokenApi(values: RefreshTokenRequest) {
  return apiClient<RefreshTokenRequest, LoginResponse>(
    '/token/refresh',
    'POST',
    values,
  )
}

export function resetPasswordApi(values: ResetPasswordRequest) {
  return apiClient<ResetPasswordRequest, ResetPasswordResponse>(
    '/account/password/reset',
    'POST',
    values,
  )
}

export function setupNewPassword(values: SetupNewPasswordRequest) {
  return apiClient<SetupNewPasswordRequest, LoginResponse>(
    '/account/password/create',
    'POST',
    values,
  )
}

export function verifyAccountApi(values: VerifyAccountRequest) {
  return apiClient<VerifyAccountRequest, LoginResponse>(
    '/token/verify',
    'POST',
    values,
  )
}
