import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { Text } from 'components/catalyst/text'
import { RouteNames } from '@vectroid/shared/const'
import { ShieldAlert } from 'lucide-react'

export default function PendingVerificationPage() {
  return (
    <div className="text-center">
      <Heading level={1} divider={false} className="w-full">
        <div className="mb-4 flex justify-center">
          <ShieldAlert size={48} strokeWidth={1.5} className="text-amber-300" />
        </div>
        Account Verification Required
      </Heading>

      <Text className="!-mt-1 mb-6">
        We've sent you an email with a link to verify your account. Please check your inbox and click the link to
        complete the verification process.
      </Text>

      <Button href={RouteNames.Logout}>Back to Login</Button>
    </div>
  )
}
