import { FileText as DocumentTextIcon, FolderTree, Home, Key, Layers, Terminal } from 'lucide-react'
import { RouteNames } from '@vectroid/shared/const'
import { appConfig } from '@vectroid/shared/config'

export const SidebarMenuItems = [
  [
    { text: 'Dashboard', href: RouteNames.Dashboard, icon: <Home size={16} /> },
    { text: 'Datasets', href: RouteNames.Datasets, icon: <FolderTree size={16} /> },
    { text: 'Indexes', href: RouteNames.Indexes, icon: <Layers size={16} /> },
    { text: 'API Keys', href: RouteNames.ApiKeys, icon: <Key size={16} /> },
    { text: 'API Playground', href: RouteNames.ApiPlayground, icon: <Terminal size={16} /> },
  ],
  { spacer: true },
  [
    {
      text: 'API Docs',
      href: appConfig.DOCS_BASE_URL,
      icon: <DocumentTextIcon size={16} />,
      onSelect: () => {
        window.open(appConfig.DOCS_BASE_URL)
      },
    },
  ],
]
