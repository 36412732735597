import { Dataset } from "modules/datasets";
import { Index } from "modules/indexes";
import { useTrackRecentResources } from "../store";
import { Heading } from "components/catalyst/heading";
import { DashboardRecentItem } from "./dashboard-recent-item";
import { RouteNames } from '@vectroid/shared/const'
import { ArrowRightIcon, FolderDot, Layers2 } from "lucide-react";
import { getDatasetStatusBadgeColor } from "modules/datasets/utils";
import { Badge } from "components/catalyst/badge";
import { Link } from "react-router-dom";
import { Text } from "components/catalyst/text";

type Props = {
  datasets: Dataset[];
  indexes: Index[];
};

export function DashboadRecentlyViewed({ datasets, indexes }: Props) {
  const { recentDatasetNames, recentIndexNames } = useTrackRecentResources();

  const filteredDatasets = recentDatasetNames
    .map((name) => datasets.find((dataset) => dataset.name === name))
    .filter((dataset) => dataset !== undefined) as Dataset[];

  const filteredIndexes = recentIndexNames
    .map((name) => indexes.find((index) => index.name === name))
    .filter((index) => index !== undefined) as Index[];

  return (
    <div className="mt-3 space-y-8 max-w-5xl">
      <section>
        <Heading className="!text-lg !items-center w-full flex">
          <span className="flex-1">Recent Datasets</span>
          <Link
            to={RouteNames.Datasets}
            className="text-xs font-normal mt-1 hover:opacity-100 opacity-70"
          >
            <Text className="flex items-center space-x-2">
              <span>View all</span>
              <ArrowRightIcon size={16} />
            </Text>
          </Link>
        </Heading>
        <div className="grid grid-cols-12 space-x-6">
          {filteredDatasets.map((dataset) => (
            <DashboardRecentItem
              label={dataset.name}
              href={RouteNames.DatasetDetail.get(dataset.name)}
              headerColor="dark:bg-amber-900/10 bg-amber-50"
              icon={
                <div className="flex items-center justify-center w-12 h-12 bg-amber-200 dark:bg-amber-800/20 rounded-full">
                  <FolderDot size={22} />
                </div>
              }
            >
              <Badge color={getDatasetStatusBadgeColor(dataset.status)}>
                {dataset.status}
              </Badge>
            </DashboardRecentItem>
          ))}
        </div>
      </section>

      <section>
        <Heading className="!text-lg !items-center w-full flex">
          <span className="flex-1">Recent Indexes</span>
          <Link
            to={RouteNames.Indexes}
            className="text-xs font-normal mt-1 hover:opacity-100 opacity-70"
          >
            <Text className="flex items-center space-x-2">
              <span>View all</span>
              <ArrowRightIcon size={16} />
            </Text>
          </Link>
        </Heading>
        <div className="grid grid-cols-12 space-x-6">
          {filteredIndexes.map((index) => (
            <DashboardRecentItem
              label={index.name}
              href={RouteNames.IndexDetail.get(index.name)}
              headerColor="dark:bg-sky-900/10 bg-sky-50"
              icon={
                <div className="flex items-center justify-center w-12 h-12 bg-sky-200 dark:bg-sky-800/30 rounded-full">
                  <Layers2 size={22} />
                </div>
              }
            >
              <Badge>{index.similarityFunction}</Badge>
            </DashboardRecentItem>
          ))}
        </div>
      </section>
    </div>
  );
}
