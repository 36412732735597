import { appConfig } from '@vectroid/shared/config'
import { Badge } from 'components/catalyst/badge'
import { Button } from 'components/catalyst/button'
import { Play } from 'lucide-react'
import { useCollectionStore } from 'modules/indexes'
import { useEffect, useRef, useState } from 'react'

type Props = {
  executeCode: (httpUrl: string) => void
  loading: boolean
}

export function ConsoleHeader(props: Props) {
  const { activeCollection } = useCollectionStore()

  const [httpUrl, setHttpUrl] = useState(activeCollection.endpoint)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setHttpUrl(activeCollection.endpoint)
    if (/\{[^}]+\}/.test(activeCollection.endpoint)) {
      inputRef.current?.focus()
    }
  }, [activeCollection])

  return (
    <>
      <div className="mb-4 flex items-center rounded-lg border bg-white dark:bg-zinc-950">
        <Badge
          className="!w-16 justify-center !rounded-r-none border-r !px-3 !py-2 font-medium"
          color={activeCollection.color as any}
        >
          {activeCollection.method}
        </Badge>

        <span className="pl-2 font-mono text-sm">{appConfig.BASE_URL}</span>

        <input
          ref={inputRef}
          value={httpUrl}
          onChange={(e) => setHttpUrl(e.target.value)}
          className="flex h-[24px] w-auto items-center rounded-md border-2 border-dashed border-transparent bg-amber-500/30 pl-1 font-mono text-sm text-zinc-700 !outline-none focus:border-amber-500 dark:bg-amber-900/30 dark:text-white focus:dark:border-amber-700"
          style={{ width: `${httpUrl.length + 1}ch` }}
        />

        <span className="flex-1" />

        <Button
          color="amber"
          className="!rounded-l-none !px-4"
          onClick={() => props.executeCode(httpUrl)}
          loading={props.loading}
        >
          <Play size={16} strokeWidth={2.5} />
          Run
        </Button>
      </div>
    </>
  )
}
