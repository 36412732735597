import { ColumnDefinition, CommonTable } from 'components/common'
import { RouteNames } from '@vectroid/shared/const'
import { useSearchParamFilteredArray } from 'hooks/use-search-query-param'
import { Index } from '../types'

type Props = {
  loading: boolean
  indexes: Index[]
}

export function IndexesStackLayout(props: Props) {
  const filteredIndexes = useSearchParamFilteredArray(props.indexes, { searchKeys: ['name'] })

  const columns: ColumnDefinition<Index>[] = [
    { key: 'id', header: 'ID' },
    { key: 'name', header: 'Index Name', primary: true },
    { key: 'similarityFunction', header: 'Similarity Function' },
    { key: 'actions', header: 'Actions' },
  ]

  return (
    <CommonTable<Index>
      columns={columns}
      data={filteredIndexes}
      loading={props.loading}
      setRowLink={(row) => RouteNames.IndexDetail.get(row.name)}
    />
  )
}
