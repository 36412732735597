import { useDatasetStore } from '../store/dataset.store'
import { DatasetEmptyState } from './dataset-empty-state'
import { DatasetStackedLayout } from './dataset-stacked-layout'

export function DatasetListView() {
  const { datasets, loading } = useDatasetStore()

  if (!loading && datasets.length === 0) {
    return <DatasetEmptyState />
  }

  return <DatasetStackedLayout />
}
