import { create } from 'zustand'

type WizardState = {
  currentStep: number
  handleNext: () => void
  handlePrevious: () => void
  resetWizard: () => void
}

type WizardStoreProps = {
  initialStep?: number
  stepLength: number
}

export const createWizardStore = ({ initialStep = 0, stepLength }: WizardStoreProps) => {
  return create<WizardState>((set) => ({
    currentStep: initialStep,

    handleNext: () =>
      set((state) => {
        const nextStep = Math.min(state.currentStep + 1, stepLength - 1)
        return { currentStep: nextStep }
      }),

    handlePrevious: () =>
      set((state) => {
        const previousStep = Math.max(state.currentStep - 1, 0)
        return { currentStep: previousStep }
      }),

    resetWizard: () => set({ currentStep: 0 }),
  }))
}
