import * as Headless from '@headlessui/react'
import { cn } from '@vectroid/shared/utils'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from 'components/catalyst/dropdown'
import { CommandBar } from 'components/command-bar'
import { DocsPanel } from 'components/docs-panel'
import { NotificationDropdown } from 'components/notification-dropdown'
import { ThemeToggle } from 'components/theme-toggle'
import { RouteNames } from '@vectroid/shared/const'
import { useDocsPanel } from 'context/docs-provider'
import { useTheme } from 'context/theme-provider'
import {
  LogOut as ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  Lightbulb as LightBulbIcon,
  ShieldCheckIcon,
  UserCircle2Icon,
} from 'lucide-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from '@vectroid/shared/auth'
import { Divider } from './divider'
import { NavbarItem } from './navbar'
import { SidebarItem } from './sidebar'

export function AccountDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {
  const navigate = useNavigate()

  function handleLogout() {
    navigate(RouteNames.Logout)
  }

  return (
    <DropdownMenu className="min-w-64" anchor={anchor}>
      <DropdownItem href="#">
        <UserCircle2Icon size={16} className="mr-3" />
        <DropdownLabel>My account</DropdownLabel>
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem href="#">
        <ShieldCheckIcon size={16} className="mr-3" />
        <DropdownLabel>Privacy policy</DropdownLabel>
      </DropdownItem>
      <DropdownItem href="#">
        <LightBulbIcon size={16} className="mr-3" />
        <DropdownLabel>Share feedback</DropdownLabel>
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem onClick={handleLogout}>
        <ArrowRightStartOnRectangleIcon size={16} className="mr-3" />
        <DropdownLabel>Sign out</DropdownLabel>
      </DropdownItem>
    </DropdownMenu>
  )
}

function OpenMenuIcon() {
  return (
    <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true">
      <path d="M2 6.75C2 6.33579 2.33579 6 2.75 6H17.25C17.6642 6 18 6.33579 18 6.75C18 7.16421 17.6642 7.5 17.25 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75ZM2 13.25C2 12.8358 2.33579 12.5 2.75 12.5H17.25C17.6642 12.5 18 12.8358 18 13.25C18 13.6642 17.6642 14 17.25 14H2.75C2.33579 14 2 13.6642 2 13.25Z" />
    </svg>
  )
}

function CloseMenuIcon() {
  return (
    <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true">
      <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
    </svg>
  )
}

function MobileSidebar({ open, close, children }: React.PropsWithChildren<{ open: boolean; close: () => void }>) {
  return (
    <Headless.Dialog open={open} onClose={close} className="lg:hidden">
      <Headless.DialogBackdrop
        transition
        className="fixed inset-0 bg-black/30 transition data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <Headless.DialogPanel
        transition
        className="fixed inset-y-0 w-full max-w-80 p-2 transition duration-300 ease-in-out data-[closed]:-translate-x-full"
      >
        <div className="flex h-full flex-col rounded-lg bg-white shadow-sm ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10">
          <div className="-mb-3 px-4 pt-3">
            <Headless.CloseButton as={NavbarItem} aria-label="Close navigation">
              <CloseMenuIcon />
            </Headless.CloseButton>
          </div>
          {children}
        </div>
      </Headless.DialogPanel>
    </Headless.Dialog>
  )
}

export function SidebarLayout({
  navbar,
  sidebar,
  children,
}: React.PropsWithChildren<{ navbar: React.ReactNode; sidebar: React.ReactNode }>) {
  let [showSidebar, setShowSidebar] = useState(false)
  const account = useAuthStore((state) => state.account)
  const { visible: showDocsPanel } = useDocsPanel()

  const { sidebarExpanded, setSidebarExpanded } = useTheme()

  return (
    <div className="relative isolate flex min-h-svh w-full bg-white dark:bg-zinc-900 max-lg:flex-col lg:bg-white dark:lg:bg-zinc-950">
      {/* Sidebar on desktop */}
      <div
        className={cn(
          'fixed inset-y-0 left-0 z-20 transition-all dark:bg-zinc-950 max-lg:hidden',
          sidebarExpanded ? 'w-52' : 'w-20'
        )}
        // onMouseEnter={() => setSidebarExpanded(true)}
        // onMouseLeave={() => setSidebarExpanded(false)}
      >
        {sidebar}
      </div>

      {/* Documentation */}
      {showDocsPanel && (
        <div className={'fixed inset-y-0 right-0 z-20 w-[520px] overflow-hidden p-2 max-lg:hidden'}>
          <DocsPanel />
        </div>
      )}

      {/* Sidebar on mobile */}
      <MobileSidebar open={showSidebar} close={() => setShowSidebar(false)}>
        {sidebar}
      </MobileSidebar>

      {/* Navbar on mobile */}
      <header className="flex items-center px-4 lg:hidden">
        <div className="py-2.5">
          <NavbarItem onClick={() => setShowSidebar(true)} aria-label="Open navigation">
            <OpenMenuIcon />
          </NavbarItem>
        </div>
        <div className="min-w-0 flex-1">{navbar}</div>
      </header>

      {/* Content */}
      <main
        className={cn(
          'flex flex-1 flex-col transition-all lg:min-w-0 lg:pt-3',
          sidebarExpanded ? 'lg:pl-52' : 'lg:pl-20',
          showDocsPanel ? 'lg:pr-[520px]' : ''
        )}
      >
        <div className={cn('mb-3 flex items-center space-x-3 px-4')}>
          <span className={'flex-1'} />
          <CommandBar />

          <ThemeToggle />

          <NotificationDropdown />

          <Dropdown>
            <DropdownButton as={SidebarItem} href="#" className="!p-0 text-left">
              <span className="flex min-w-0 items-center gap-3 px-1">
                <span className="min-w-0">
                  <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                    {account?.name}
                  </span>
                  <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                    {account?.email}
                  </span>
                </span>
              </span>
              <ChevronDownIcon size={16} />
            </DropdownButton>
            <AccountDropdownMenu anchor="top start" />
          </Dropdown>
        </div>

        <Divider />
        <div className={cn('!lg:px-6 grow overflow-y-scroll bg-zinc-50 p-6 dark:bg-zinc-900/50')} id="application-main">
          <div className="mx-auto flex min-h-full flex-col">{children}</div>
        </div>
      </main>
    </div>
  )
}
