import { z } from 'zod'

export const InviteUsersFormSchema = z.object({
  users: z.array(
    z.object({
      email: z
        .string()
        .email({ message: 'Invalid email address' })
        .max(50, { message: 'Email cannot exceed 50 characters' }),
      role: z.enum(['read-write', 'read-only']),
    })
  ),
})
