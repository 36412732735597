import { createWizardStore } from 'components/common/wizard/store'
import { Index } from 'modules/indexes'
import { create } from 'zustand'
import { DatasetCreateSteps, DatasetCreateStepsLength } from '../const'
import { Dataset } from '../types'

type State = {
  dataset: Dataset | null
  index: Index | null
}

type Actions = {
  setDataset: (dataset: Dataset) => void
  setIndex: (index: Index) => void
}

/**
 * Store for dataset create
 *
 * @returns {Dataset} - The dataset
 * @returns {Index} - The index
 * @returns {(dataset: Dataset) => void} - Function to set the dataset
 * @returns {(index: Index) => void} - Function to set the index
 */
export const useDatasetCreateStore = create<State & Actions>((set) => ({
  dataset: null,
  index: null,
  setDataset: (dataset: Dataset) => set({ dataset }),
  setIndex: (index: Index) => set({ index }),
}))

/**
 * Store for dataset create wizard
 *
 * @returns {object} - The dataset create wizard store
 * @returns {DatasetCreateSteps} - The current step
 */
export const useDatasetCreateWizardStore = createWizardStore({
  initialStep: DatasetCreateSteps.DATASET_CREATE,
  stepLength: DatasetCreateStepsLength,
})
