import { GridItemSkeleton } from 'components/skeletons'
import { useElementSize } from 'hooks/use-element-size'
import { cn } from '@vectroid/shared/utils'

type Props = {
  children: React.ReactNode
  loading?: boolean
}

export function GridLayout(props: Props) {
  const { width: mainLayoutWidth } = useElementSize('#application-main')

  const loadingState = (
    <>
      {new Array(6).fill(null).map((index, i) => (
        <GridItemSkeleton key={`IndexGridItemSkeleton-${i}`} />
      ))}
    </>
  )

  return (
    <div
      className={cn('grid grid-cols-1 gap-6', {
        'grid-cols-1': mainLayoutWidth! < 760,
        'grid-cols-3': mainLayoutWidth! > 760,
        'grid-cols-4': mainLayoutWidth! >= 1000 && mainLayoutWidth! < 2000,
        'grid-cols-6': mainLayoutWidth! >= 2000,
      })}
    >
      {props.loading ? loadingState : props.children}
    </div>
  )
}
