import { useAuthStore } from '@vectroid/shared/auth'
import React from 'react'

const Logout: React.FC = () => {
  const logout = useAuthStore((state) => state.logout)

  React.useEffect(() => {
    logout()
    window.location.href = '/console'
  }, [])

  return <></>
}

export default Logout
