export const PythonNativeSnippet = `import http.client

conn = http.client.HTTPSConnection("{{host}}")

payload = '{{body}}'

headers = {
    'x-api-key': "{{apiKey}}",
    'Accept': "application/json",
    'Content-Type': "application/json"
}

conn.request("POST", "{{endpoint}}", payload, headers)

res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))`

export const PythonRequestsSnippet = `import requests

url = "{{url}}"

payload = '{{body}}'
headers = {
  "x-api-key": "{{apiKey}}",
  "Accept": "application/json",
  "Content-Type": "application/json"
}

response = requests.post(url, data=payload, headers=headers)

print(response.json())`
