import { Divider } from 'components/catalyst/divider'
import { Field } from 'components/catalyst/fieldset'
import { Subheading } from 'components/catalyst/heading'
import { Input } from 'components/catalyst/input'
import { Text } from 'components/catalyst/text'
import { CopyButton } from 'components/copy-button'
import { appConfig } from '@vectroid/shared/config'
import { SdkClientPlayground } from 'modules/api-playground/components/sdk-client-playground'
import { useAuthStore } from '@vectroid/shared/auth'

const CREATE_INDEX_BODY_OBJECT = `{
   "indexName": "my-index",
   "similarityFunction": "cosine",
   "cloud": "gcp",
   "region": "us-east1"
}`

function AccessDatabasePage() {
  const { apiKey } = useAuthStore()
  const url = `${appConfig.API_BASE_URL}/index`

  return (
    <>
      <section>
        <div className="col-span-3 mt-6 max-w-4xl">
          <section className="flex items-start space-x-2">
            <div className="mt-2 w-[200px]">
              <Subheading>API Base URL</Subheading>
            </div>
            <Field className="flex-1">
              <Input readOnly defaultValue={url} />
              <Text className="mt-2 !text-xs">A RESTful endpoint for querying and managing your database.</Text>
            </Field>

            <CopyButton text={url} />
          </section>

          <Divider className="my-6" />

          <section className="flex items-start space-x-2">
            <div className="mt-2 w-[200px]">
              <Subheading>API Key</Subheading>
            </div>
            <Field className="flex-1">
              <Input readOnly defaultValue={apiKey as string} />
              <Text className="mt-2 !text-xs">
                A unique identifier for authenticating your requests. Keep this key secure.
              </Text>
            </Field>
            <CopyButton text={apiKey as string} />
          </section>

          <Divider className="my-6" />

          <SdkClientPlayground bodyObject={CREATE_INDEX_BODY_OBJECT} />
        </div>
      </section>
    </>
  )
}

export default AccessDatabasePage
