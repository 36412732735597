import {
  isEscapeCharBodyLang,
  replaceStaticVariables,
} from '@vectroid/shared/api-collections'
import { appConfig } from '@vectroid/shared/config'
import { CodeSnippetLanguages, Snippets } from '@vectroid/shared/snippets'
import { useUserDefaultsStore } from '@vectroid/shared/store'
import { useAuthStore } from '@vectroid/shared/auth'
import { useCollectionStore } from 'modules/indexes'
import { useEffect, useState } from 'react'

export function useCodeGenerator(bodyObject: string) {
  const preferredCodeLang = useUserDefaultsStore(
    (state) => state.preferredCodeLang,
  )
  const selectedSnippet = CodeSnippetLanguages.find(
    (c) => c.id === preferredCodeLang,
  )
  const [generatedCode, setGeneratedCode] = useState('')

  const { account, apiKey } = useAuthStore()
  const { activeCollection } = useCollectionStore()

  const handleSnippetChange = () => {
    if (!selectedSnippet) {
      return
    }

    try {
      const url = `${appConfig.API_BASE_URL}${activeCollection?.endpoint}`
      const parsedBody = JSON.stringify(JSON.parse(bodyObject))
      const snippet = Snippets[selectedSnippet.id]
      const codeSnippet = replaceStaticVariables(snippet, {
        url,
        apiKey: apiKey!,
        body: isEscapeCharBodyLang(selectedSnippet.id)
          ? parsedBody.replaceAll('"', '\\"')
          : parsedBody,
        endpoint: `/api/v1${activeCollection?.endpoint}`,
        host: `${account?.endpoint}.api.vectroid.com`,
        contentLength: parsedBody.length.toString(),
      })
      setGeneratedCode(codeSnippet)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (selectedSnippet) {
      handleSnippetChange()
    }
  }, [selectedSnippet, bodyObject])

  return {
    handleSnippetChange,
    generatedCode,
    selectedSnippet,
  }
}
