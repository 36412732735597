import { FileCode2 } from 'lucide-react'

import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'

import { RouteNames } from '@vectroid/shared/const'
import { useDocsPanel } from 'context/docs-provider'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { IndexDetailContext } from '../context'

const IndexDetailTabs = [
  { label: 'Query Console', href: RouteNames.IndexDetailConsole.name },
  { label: 'Access Database', href: RouteNames.IndexDetailAccessDB.name },
  { label: 'Settings', href: RouteNames.IndexDetailSettings.name },
].map((tab) => ({ ...tab, isPageTab: true }))

export function IndexDetailHeader() {
  const indexDetail = useContext(IndexDetailContext)

  const { openDocsPanel } = useDocsPanel()
  const { pathname } = useLocation()

  function showDocs() {
    openDocsPanel('/query/#query-a-vector')
  }

  const tabs = IndexDetailTabs.map((tab) => ({
    ...tab,
    current: pathname.includes(tab.href),
  }))

  return (
    <>
      <Heading
        actions={
          <Button onClick={showDocs} className="max-sm:w-full" outline>
            <FileCode2 size={16} /> API Docs
          </Button>
        }
        pageHeading
        breadcrumb={{ label: 'Indexes', path: RouteNames.Indexes }}
        tabs={tabs}
      >
        {indexDetail.name}
      </Heading>
    </>
  )
}
