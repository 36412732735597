import { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { Banner } from 'components/banner'
import { DetailPageSkeleton } from 'components/skeletons'
import { RouteNames } from '@vectroid/shared/const'
import { IndexDetailHeader } from 'modules/indexes/components/index-detail-header'
import { IndexDetailContext } from 'modules/indexes/context'
import { useIndexStore } from 'modules/indexes/store'
import { useTrackRecentResources } from "modules/dashboard/store";

function IndexDetailPage() {
  const params = useParams()
  const navigate = useNavigate()
  useTrackRecentResources();

  const { getIndexByName, loading: indexLoading, fetchIndexes } = useIndexStore()
  const indexDetail = getIndexByName(params.indexName!)

  useEffect(() => {
    fetchIndexes()
  }, [])

  if (indexLoading) {
    return <DetailPageSkeleton />
  }

  if (!indexDetail) {
    return (
      <Banner
        type="error"
        message="Index not found"
        actionBtns={[{ label: 'Go Dashboard', onClick: () => navigate(RouteNames.Dashboard) }]}
      />
    )
  }

  return (
    <IndexDetailContext.Provider value={indexDetail}>
      <IndexDetailHeader />

      <Outlet />
    </IndexDetailContext.Provider>
  )
}

export default IndexDetailPage
