import { Badge } from 'components/catalyst/badge'
import { Card } from 'components/catalyst/card'
import { CloudProviderIcon } from 'components/cloud-provider-icon'
import { useDatasetDetailStore } from '../store/datase-detail.store'
import { getDatasetStatusBadgeColor } from '../utils'
export function DatasetOverview() {
  const dataset = useDatasetDetailStore((state) => state.dataset)

  const items = [
    { name: 'Dimension', value: dataset?.dimensions },
    {
      name: 'Status',
      value: <Badge color={getDatasetStatusBadgeColor(dataset?.status)}>{dataset?.status}</Badge>,
    },
    { name: 'Region', value: dataset?.region?.toLowerCase().replace(/_/g, '-') },
    {
      name: 'Cloud Provider',
      value: <CloudProviderIcon provider={dataset?.provider!} />,
    },
  ]

  return (
    <Card className="mx-auto grid grid-cols-1 gap-px p-0 sm:grid-cols-2 lg:grid-cols-4">
      {items.map((item) => (
        <div
          key={item.name}
          className="flex flex-wrap items-baseline justify-between gap-x-4 p-4 sm:px-6 xl:px-8"
        >
          <dt className="text-sm/6 font-medium text-zinc-500">{item.name}</dt>
          <dd className="w-full flex-none text-base/12 font-medium tracking-tight">
            {item.value}
          </dd>
        </div>
      ))}
    </Card>
  );
}
