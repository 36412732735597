import { useAsyncData } from 'hooks/use-async-data'
import { useCallback } from 'react'
import { create } from 'zustand'
import { listIndexesApi } from '../services/index.services'
import { Index } from '../types'

type State = {
  indexes: Index[]
  setIndexes: (indexes: Index[]) => void
}

const indexStore = create<State>((set) => ({
  indexes: [],
  setIndexes: (indexes: any[]) => set({ indexes }),
}))

export function useIndexStore() {
  const { indexes, setIndexes } = indexStore()
  const { loading, fetchData } = useAsyncData(listIndexesApi, {
    onDataFetched: (fetchedIndexes) => setIndexes(fetchedIndexes?.indexes),
    showErrorMessage: true,
  })

  const getIndexByName = useCallback(
    (name: string | number) => {
      return indexes.find((index) => index.name === name || index.id == name)
    },
    [indexes]
  )

  const fetchIndexes = useCallback(() => {
    fetchData()
  }, [indexes, fetchData])

  return { indexes, loading, fetchIndexes, getIndexByName }
}
