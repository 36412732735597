import { CodeLanguage, Snippet } from '@vectroid/shared/snippets';
import { cn } from '@vectroid/shared/utils';

export function CodeLanguageIcon({ item, className }: { item: Snippet; className?: string }) {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/static/icons/lang/${item.lang}.svg`}
      alt="icon"
      title={`${item.label} icon`}
      width="22"
      className={cn(className, {
        'dark:invert dark:filter': [CodeLanguage.Curl, CodeLanguage.Shell].includes(item.lang),
      })}
    />
  )
}
