import { Collection, GLOBAL_API_PLAYGROUND_COLLECTION } from '@vectroid/shared/api-collections'
import { create } from 'zustand'

type State = {
  activeCollection: Collection
  activeCollectionAPIBaseURL: string
}

type Actions = {
  setActiveCollection: (collection: Collection) => void
  setActiveCollectionAPIBaseURL: (url: string) => void
}

export const useCollectionStore = create<State & Actions>((set) => ({
  activeCollection: GLOBAL_API_PLAYGROUND_COLLECTION[0].items[0],
  activeCollectionAPIBaseURL: 'https://api.example.com',
  setActiveCollectionAPIBaseURL: (url: string) => set({ activeCollectionAPIBaseURL: url }),
  setActiveCollection: (collection: Collection) => set({ activeCollection: collection }),
}))
