import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getInitials(name = '') {
  return name
    .split(' ')
    .map((n) => n?.charAt(0)?.toUpperCase())
    .join('')
}

export const ctrlKey = navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'

export const isLocalhost = typeof window !== 'undefined' && Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
)

export function delay(ms = 1000) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function matchDynamicRoute(routePattern: string, pathname: string) {
  if (!routePattern.includes(':')) {
    return routePattern === pathname
  }

  const routeParts = routePattern.split('/')
  const pathParts = pathname.split('/')

  if (routeParts.length !== pathParts.length) {
    return false
  }

  return routeParts.every((part, index) => {
    return part.startsWith(':') || part === pathParts[index]
  })
}

export function formatNumber(num: number) {
  return num.toLocaleString()
}

export function slugify(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .trim()
}

type NestedObject = { [key: string]: any }

export function convertToNestedObject(
  flatObject: Record<string, any>,
): NestedObject {
  const nestedObject: NestedObject = {}

  for (const key in flatObject) {
    const value = flatObject[key]
    const keys = key.split('.')

    let currentLevel = nestedObject

    keys.forEach((part, index) => {
      if (index === keys.length - 1) {
        // Last part of the key, assign the value
        currentLevel[part] = value
      } else {
        // Create nested object if it doesn't exist
        if (!currentLevel[part]) {
          currentLevel[part] = {}
        }
        currentLevel = currentLevel[part]
      }
    })
  }

  return nestedObject
}