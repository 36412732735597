import { PublicDataset } from '../types'

export const PUBLIC_DATASETS: PublicDataset[] = [
  {
    name: 'DEEP1B',
    dimensions: 96,
    trainSize: 9990000,
    testSize: 10000,
    neighbors: 100,
    distance: 'Angular',
    download: 'http://ann-benchmarks.com/deep-image-96-angular.hdf5',
  },
  {
    name: 'Fashion-MNIST',
    dimensions: 784,
    trainSize: 60000,
    testSize: 10000,
    neighbors: 100,
    distance: 'Euclidean',
    download: 'http://ann-benchmarks.com/fashion-mnist-784-euclidean.hdf5',
  },
  {
    name: 'GIST',
    dimensions: 960,
    trainSize: 1000000,
    testSize: 1000,
    neighbors: 100,
    distance: 'Euclidean',
    download: 'http://ann-benchmarks.com/gist-960-euclidean.hdf5',
  },
  {
    name: 'GloVe-25',
    dimensions: 25,
    trainSize: 1183514,
    testSize: 10000,
    neighbors: 100,
    distance: 'Angular',
    download: 'http://ann-benchmarks.com/glove-25-angular.hdf5',
  },
  {
    name: 'GloVe-50',
    dimensions: 50,
    trainSize: 1183514,
    testSize: 10000,
    neighbors: 100,
    distance: 'Angular',
    download: 'http://ann-benchmarks.com/glove-50-angular.hdf5',
  },
  {
    name: 'GloVe-100',
    dimensions: 100,
    trainSize: 1183514,
    testSize: 10000,
    neighbors: 100,
    distance: 'Angular',
    download: 'http://ann-benchmarks.com/glove-100-angular.hdf5',
  },
  {
    name: 'GloVe-200',
    dimensions: 200,
    trainSize: 1183514,
    testSize: 10000,
    neighbors: 100,
    distance: 'Angular',
    download: 'http://ann-benchmarks.com/glove-200-angular.hdf5',
  },
  {
    name: 'Kosarak',
    dimensions: 27983,
    trainSize: 74962,
    testSize: 500,
    neighbors: 100,
    distance: 'Jaccard',
    download: 'http://ann-benchmarks.com/kosarak-jaccard.hdf5',
  },
  {
    name: 'MNIST',
    dimensions: 784,
    trainSize: 60000,
    testSize: 10000,
    neighbors: 100,
    distance: 'Euclidean',
    download: 'http://ann-benchmarks.com/mnist-784-euclidean.hdf5',
  },
  {
    name: 'MovieLens-10M',
    dimensions: 65134,
    trainSize: 69363,
    testSize: 500,
    neighbors: 100,
    distance: 'Jaccard',
    download: 'http://ann-benchmarks.com/movielens10m-jaccard.hdf5',
  },
  {
    name: 'NYTimes',
    dimensions: 256,
    trainSize: 290000,
    testSize: 10000,
    neighbors: 100,
    distance: 'Angular',
    download: 'http://ann-benchmarks.com/nytimes-256-angular.hdf5',
  },
  {
    name: 'SIFT',
    dimensions: 128,
    trainSize: 1000000,
    testSize: 10000,
    neighbors: 100,
    distance: 'Euclidean',
    download: 'http://ann-benchmarks.com/sift-128-euclidean.hdf5',
  },
  {
    name: 'Last.fm',
    dimensions: 65,
    trainSize: 292385,
    testSize: 50000,
    neighbors: 100,
    distance: 'Angular',
    download: 'http://ann-benchmarks.com/lastfm-64-dot.hdf5',
  },
].map((dataset, index) => ({ ...dataset, id: index + 1 }))
