import { Background, BackgroundVariant, Controls, Edge, Node, Panel, ReactFlow } from '@xyflow/react'

import '@xyflow/react/dist/style.css'
import { Badge } from 'components/catalyst/badge'
import { Card } from 'components/catalyst/card'
import { useTheme } from 'context/theme-provider'
import { cn } from '@vectroid/shared/utils'
import { Database, Expand, FolderTree, Shrink } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useDatasetDetailStore } from '../store/datase-detail.store'

export function DatasetIndexHierarchyOverview() {
  const { theme } = useTheme()
  const [fullscreen, setFullscreen] = useState(false)

  const datasetDetailStore = useDatasetDetailStore()
  const { dataset, datasetIndexes } = datasetDetailStore

  const initialNodes: Node[] = [
    {
      id: '1',
      position: { x: datasetIndexes?.length === 1 ? 0 : 200, y: 0 },
      data: {
        label: dataset?.name,
        type: 'dataset',
      },
      className: '!border-sky-600',
    },
    ...(datasetIndexes || []).map((index, i) => ({
      id: (i + 2).toString(),
      position: { x: i * 200, y: 160 },
      data: { label: index.name, custom: index.similarityFunction },
      style: { borderRadius: 8 },
    })),
  ]

  const initialEdges: Edge[] = (datasetIndexes || []).map((index, i) => ({
    id: `e1-${i + 4}`,
    source: '1',
    target: (i + 2).toString(),
    label: 'Continues',
    animated: true,
  }))

  function registerEvents() {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault()
        setFullscreen(false)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }

  const reactFlowKey = JSON.stringify({
    name: dataset?.name,
    initialNodes: initialNodes.length,
    edges: initialEdges.length,
    fullscreen: fullscreen ? 'fullscreen' : 'normal',
  })

  useEffect(() => {
    registerEvents()
  }, [])

  const nodes = initialNodes.map((node) => {
    return {
      ...node,
      data: {
        label: (
          <span className="flex flex-col items-center justify-center space-y-2">
            {node.data?.type === 'dataset' ? <FolderTree size={14} /> : <Database size={14} />}{' '}
            <span>{node.data?.label as any}</span>
            {(node.data?.custom as any) && <Badge className="!text-xs">{node.data?.custom as any}</Badge>}
          </span>
        ),
      },
      className: cn('border !border-amber-600/40 !rounded-lg dark:!bg-zinc-900', node.className),
    }
  })

  return (
    <Card
      className={cn(
        'max-w-5xl',
        fullscreen ? 'fixed inset-0 z-[100] h-[screen] w-screen max-w-full bg-white dark:bg-black' : 'relative'
      )}
      contentClasses={cn('h-[500px] rounded-lg overflow-hidden', fullscreen ? '!h-screen !w-full bg-black' : '')}
      heading="Dataset - Index Relationships"
      actions={
        <button
          className="flex items-center space-x-2"
          onClick={() => setFullscreen(!fullscreen)}
          aria-label="Toggle Fullscreen"
        >
          {!fullscreen ? <Expand size={18} /> : <Shrink size={18} />}
        </button>
      }
    >
      <ReactFlow
        key={reactFlowKey}
        nodes={nodes}
        edges={initialEdges}
        colorMode={theme}
        fitView
        fitViewOptions={{
          maxZoom: fullscreen ? 1.8 : 1.35,
        }}
      >
        <Controls />
        <Background style={{ background: 'transparent' }} variant={BackgroundVariant.Dots} gap={12} size={1} />
        <Panel position="top-left">
          <div className="flex items-center space-x-2">
            <Badge className="flex items-center !rounded-full border !bg-white px-3 text-xs dark:!bg-zinc-950 dark:!text-white">
              <span className="mr-1 block h-1.5 w-1.5 rounded-full bg-emerald-400" />
              {dataset?.region}
            </Badge>
            <Badge className="flex items-center !rounded-full border !bg-white px-3 text-xs dark:!bg-zinc-950 dark:!text-white">
              Dimension: {dataset?.dimensions}
            </Badge>
          </div>
        </Panel>
      </ReactFlow>
    </Card>
  )
}
