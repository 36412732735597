import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { useDocsPanel } from 'context/docs-provider'
import { TabItem } from 'hooks/use-tabs'
import { FileCode2 } from 'lucide-react'
import { CreateIndexDialog } from './create-index-dialog'

type Props = {
  tabs: TabItem[]
}

export function IndexesHeader(props: Props) {
  const { openDocsPanel } = useDocsPanel()

  function showDocs() {
    openDocsPanel('/insert-vectors/#create-a-collection')
  }

  return (
    <>
      <Heading
        actions={
          <>
            <Button onClick={showDocs} className="max-sm:w-full" outline>
              <FileCode2 size={16} /> API Docs
            </Button>
            <CreateIndexDialog />
          </>
        }
        pageHeading
        tabs={props.tabs}
      >
        Indexes
      </Heading>
    </>
  )
}
