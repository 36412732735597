import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

type FilterFunction<T> = (item: T, searchQuery: string) => boolean
type SortFunction<T> = (a: T, b: T) => number

interface useSearchParamFilteredArrayOptions<T> {
  searchKeys?: (keyof T)[] // For objects: specify the properties to filter
  customFilter?: FilterFunction<T> // Custom filter logic
  customSort?: SortFunction<T> // Custom sort logic
}

export function useSearchParamFilteredArray<T>(array: T[], options?: useSearchParamFilteredArrayOptions<T>): T[] {
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')?.toLowerCase() || ''

  const { searchKeys, customFilter, customSort } = options || {}

  const filteredArray = useMemo(() => {
    if (!array?.length) return []

    return array
      .filter((item) => {
        if (customFilter) {
          return customFilter(item, search)
        }

        if (searchKeys && typeof item === 'object') {
          return searchKeys.some((key) => item?.[key] && String(item[key]).toLowerCase().includes(search))
        }

        if (typeof item === 'string') {
          return item.toLowerCase().includes(search)
        }

        return false
      })
      .sort(customSort || ((a, b) => (String(a) > String(b) ? 1 : -1))) // Default sort
  }, [array, search, searchKeys, customFilter, customSort])

  return filteredArray
}
