import { RouteObject } from 'react-router-dom'

import { RouteNames } from '@vectroid/shared/const'
import ApiKeysPage from './containers'

const routes: RouteObject[] = [
  {
    path: RouteNames.ApiKeys,
    element: <ApiKeysPage />,
  },
]

export default routes
