import { RouteObject } from 'react-router-dom'

import { RouteNames } from '@vectroid/shared/const'
import { DashboardPage } from './pages'

const routes: RouteObject[] = [
  {
    path: RouteNames.Dashboard,
    element: <DashboardPage />,
  },
]

export default routes
