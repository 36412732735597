import { ColumnDefinition, CommonTable } from 'components/common'
import { CommonDropdown } from 'components/common/dropdown/dropdown'
import { RouteNames } from '@vectroid/shared/const'
import { useSearchParamFilteredArray } from 'hooks/use-search-query-param'
import { useDatasetStore } from '../store/dataset.store'
import { Dataset } from '../types'
import { Badge } from 'components/catalyst/badge'
import { getDatasetStatusBadgeColor } from '../utils'

export function DatasetStackedLayout() {
  const { datasets, loading } = useDatasetStore()

  const filteredDatasets = useSearchParamFilteredArray(datasets, { searchKeys: ['name'] })

  const columns: ColumnDefinition<Dataset>[] = [
    { key: 'id', header: 'ID' },
    { key: 'name', header: 'Name', primary: true },
    { key: 'dimensions', header: 'Dimensions' },
    {
      key: 'status',
      header: 'Status',
      cell: (row) => <Badge color={getDatasetStatusBadgeColor(row.status)}>{row.status}</Badge>,
    },
    {
      key: 'actions',
      header: 'ACTIONS',
      cell: (row) => (
        <CommonDropdown
          menuItems={[
            { key: 'edit', label: 'Edit', href: RouteNames.DatasetDetail.get(row.name) },
            { key: 'delete', label: 'Delete', href: RouteNames.DatasetDetail.get(row.name) },
          ]}
        />
      ),
    },
  ]

  return (
    <CommonTable<Dataset>
      columns={columns}
      data={filteredDatasets}
      loading={loading}
      setRowLink={(row) => RouteNames.DatasetDetail.get(row.name)}
    />
  )
}
