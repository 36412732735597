export enum SimilarityFunctions {
    Cosine = 'COSINE',
    Euclidean = 'EUCLIDEAN',
    Manhattan = 'DOT_PRODUCT',
}

export function getSimilarityFunctionOptions() {
    return [
        { label: 'Cosine', value: SimilarityFunctions.Cosine },
        { label: 'Euclidean', value: SimilarityFunctions.Euclidean, disabled: true },
        { label: 'Manhattan', value: SimilarityFunctions.Manhattan, disabled: true },
    ]
}