import { Card } from './catalyst/card'
import { Table, TableBody, TableCell, TableRow } from './catalyst/table'
import { Skeleton } from './ui/skeleton'

export function CodeSkeleton() {
  return (
    <div className="space-y-4 p-2">
      <Skeleton className="h-2 w-4" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-64" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-4" />
      <Skeleton className="h-2 w-4" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-64" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-4" />
    </div>
  )
}

export function GridItemSkeleton() {
  return (
    <Card className="space-y-4">
      <Skeleton className="h-2 w-20" />
      <Skeleton className="h-20" />
      <div className="flex justify-between">
        <Skeleton className="h-3 w-32" />
        <Skeleton className="h-3 w-16 opacity-30" />
      </div>
      <Skeleton className="h-1 w-52" />
    </Card>
  )
}

export function StackedItemSkeleton() {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <Skeleton className="h-4 w-16" />
          </TableCell>
          <TableCell>
            <Skeleton className="h-4 w-48" />
          </TableCell>
          <TableCell>
            <Skeleton className="h-4 w-24" />
          </TableCell>
          <TableCell>
            <Skeleton className="h-4 w-24" />
          </TableCell>
          <TableCell>
            <Skeleton className="h-4 w-12" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export function DetailPageSkeleton() {
  return (
    <div className="space-y-8 opacity-20">
      <div className="space-y-4">
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-12 w-full" />
      </div>

      <div className="grid grid-cols-12 gap-6">
        <div className="grid-cols-12 md:col-span-2">
          <div className="space-y-4">
            <Skeleton className="h-72 w-full" />
            <Skeleton className="h-32 w-full" />
            <Skeleton className="h-16 w-full" />
          </div>
        </div>

        <div className="grid grid-cols-12 gap-6 md:col-span-10">
          <div className="col-span-6 space-y-4">
            <Skeleton className="h-[100px] w-full" />
          </div>
          <div className="col-span-6 space-y-4">
            <Skeleton className="h-[100px] w-full" />
          </div>
        </div>
      </div>
    </div>
  )
}