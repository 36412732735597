import { useAsyncData } from 'hooks/use-async-data'
import { useCallback } from 'react'
import { create } from 'zustand'
import { listDatasetsApi } from '../services'
import { Dataset } from '../types'

type State = {
  datasets: Dataset[]
  setDatasets: (Datasets: Dataset[]) => void
}

/**
 * Store for datasets
 *
 * @returns {Dataset[]} - List of datasets
 * @returns {(datasets: Dataset[]) => void} - Function to set datasets
 */
const datasetStore = create<State>((set) => ({
  datasets: [],
  setDatasets: (datasets: any[]) => set({ datasets }),
}))

/**
 * A hook to use the dataset store
 *
 * @returns {object} - The dataset store
 * @returns {Dataset[]} - List of datasets
 * @returns {boolean} - Loading state
 * @returns {() => void} - Function to fetch datasets
 * @returns {(name: string) => Dataset | undefined} - Function to get a dataset by name
 * @returns {(datasets: Dataset[]) => void} - Function to set datasets
 */
export function useDatasetStore() {
  const { datasets, setDatasets } = datasetStore()
  const { loading, fetchData } = useAsyncData(listDatasetsApi, {
    onDataFetched: (fetchedDatasets) => setDatasets(fetchedDatasets?.datasets),
    showErrorMessage: true,
  })

  const getDatasetByName = useCallback(
    (name: string) => {
      return datasets.find((dataset) => dataset.name === name)
    },
    [datasets]
  )

  const fetchDatasets = useCallback(() => {
    fetchData()
  }, [])

  return { datasets, loading, fetchDatasets, getDatasetByName }
}
