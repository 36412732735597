import { useDatasetStore } from "modules/datasets/store";
import { useIndexStore } from "modules/indexes";
import { useCallback } from "react";

export function useDashboard() {
  const { fetchIndexes, indexes, loading: indexesLoading } = useIndexStore();
  const {
    fetchDatasets,
    datasets,
    loading: datasetsLoading,
  } = useDatasetStore();

  const loading = datasetsLoading || indexesLoading;

  const fetchResources = useCallback(() => {
    fetchDatasets();
    fetchIndexes();
  }, []);

  return { datasets, indexes, loading, fetchResources };
}
