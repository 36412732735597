import { isLocalhost } from '../utils'

export const isProdEnv = process.env.NODE_ENV === 'production'

const parsedUrl =
  typeof window !== 'undefined' ? new URL(window.location.origin) : ({} as any)

export const appConfig = {
  BASE_URL: '',
  API_BASE_URL: '',
  CONSOLE_BASE_URL: '',
  DOCS_BASE_URL: '',
  ADMIN_API_BASE_URL: '',
}

if (isLocalhost) {
  appConfig.BASE_URL = 'https://hamit.sandbox.vectroid.dev'
  appConfig.CONSOLE_BASE_URL = `http://localhost:3001/console`
  appConfig.API_BASE_URL = `https://hamit.sandbox.vectroid.dev/api/v1`
  appConfig.ADMIN_API_BASE_URL = `https://hamit.sandbox.vectroid.dev/admin/v1/rpc`
  appConfig.DOCS_BASE_URL = `${appConfig.BASE_URL}/docs`
} else {
  appConfig.BASE_URL = `${parsedUrl.protocol}//${parsedUrl.hostname}`
  appConfig.CONSOLE_BASE_URL = `${parsedUrl.protocol}//${parsedUrl.hostname}/console`
  appConfig.API_BASE_URL = `${parsedUrl.protocol}//${parsedUrl.hostname}/api/v1`
  appConfig.ADMIN_API_BASE_URL = `${parsedUrl.protocol}//${parsedUrl.hostname}/admin/v1/rpc`
  appConfig.DOCS_BASE_URL = `${parsedUrl.protocol}//${parsedUrl.hostname}/docs`
}
