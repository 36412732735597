import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { CodeSnippetTemplateIds } from '../snippets'

type State = {
  preferredCodeLang: CodeSnippetTemplateIds
}

type Action = {
  setPreferredCodeLang: (lang: CodeSnippetTemplateIds) => void
}

export const useUserDefaultsStore = create<State & Action>()(
  devtools(
    persist(
      (set) => ({
        preferredCodeLang: CodeSnippetTemplateIds.ShellCurl,
        setPreferredCodeLang: (lang) => set({ preferredCodeLang: lang }),
      }),
      {
        name: 'user-defaults',
      },
    ),
  ),
)
