import { Listbox, ListboxLabel, ListboxOption } from 'components/catalyst/listbox'
import { Field, FieldTypes, SelectOption } from '../types'

type Props = {
  formField: Field & { fieldType: FieldTypes.Select }
  field: any
  loading?: boolean
}

export function SelectField({ field, formField, ...props }: Props) {
  const options = formField.options

  return (
    <Listbox
      disabled={props.loading}
      defaultValue={field.value}
      className="flex-1"
      {...field}
      placeholder={'Select from the list'}
      as="div"
    >
      {options.map((option: SelectOption) => (
        <ListboxOption disabled={option.disabled} value={option.value} key={option.value}>
          <ListboxLabel>{option.label}</ListboxLabel>
        </ListboxOption>
      ))}
      {props.loading && (
        <ListboxOption value="" disabled={true}>
          Loading...
        </ListboxOption>
      )}
      {!props.loading && options.length === 0 && (
        <ListboxOption value="" disabled={true}>
          No resources available
        </ListboxOption>
      )}
    </Listbox>
  )
}
