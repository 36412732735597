import { json } from '@codemirror/lang-json'

import CodeMirror from '@uiw/react-codemirror'

import { cn } from '@vectroid/shared/utils'
import { Card } from 'components/catalyst/card'
import { Text } from 'components/catalyst/text'
import { CodeSkeleton } from 'components/skeletons'
import { useTheme } from 'context/theme-provider'
import { useCallback, useEffect, useState } from 'react'

import { Collection, CollectionDynamicVariables, replaceStaticVariables } from '@vectroid/shared/api-collections'
import { getCodeEditorTheme } from '../utils/console-theme'
import { CodeGenerator } from './code-generator'
import { ConsoleHeader } from './console-header'

type Props = {
  onExecute: (httpUrl: string, code: string) => void
  loading: boolean
  result: string
  dynamicVariables: CollectionDynamicVariables
  activeCollection: Collection
}

export function QueryConsole(props: Props) {
  const [code, setCode] = useState('')
  const { theme } = useTheme()
  const codeEditorTheme = getCodeEditorTheme(theme)

  const executeCode = (httpUrl: string) => {
    props.onExecute(httpUrl, code)
  }

  const onKeyDown = (e: any) => {
    // if (e.metaKey && e.which === 13) {
    //   e.preventDefault()
    //   executeCode()
    //   return false
    // }
  }

  function handleCodeChange() {
    if (props.activeCollection?.snippets?.json) {
      const rawSnippet = props.activeCollection.snippets.json
      const snippetCode = replaceStaticVariables(rawSnippet, props.dynamicVariables)
      setCode(snippetCode)
    } else {
      setCode('')
    }
  }

  function renderCodeEditorResult() {
    if (props.loading) {
      return <CodeSkeleton />
    }

    return (
      <div className="pointer-events-none">
        <CodeMirror
          theme={codeEditorTheme}
          value={props.result || ''}
          extensions={[json()]}
          data-color-mode={theme as 'light' | 'dark'}
          readOnly
        />
      </div>
    )
  }

  const onChange = useCallback((val: string) => {
    setCode(val)
  }, [])

  useEffect(() => {
    handleCodeChange()
  }, [props.activeCollection, props.dynamicVariables])

  return (
    <div className="-mb-6 flex w-full flex-1 flex-col overflow-hidden">
      <ConsoleHeader loading={props.loading} executeCode={executeCode} />

      <div className="code-editor flex flex-1">
        <div className="relative z-30 flex-1" tabIndex={0}>
          <div className="h-full cursor-text">
            <Card
              className={cn(
                'group relative flex flex-col !rounded-r-none !rounded-bl-none border border-b-0 p-4 ring-amber-900/50 ring-offset-2 ring-offset-transparent transition-all dark:!bg-zinc-950/50'
              )}
            >
              <nav className="mb-2 flex items-center gap-x-2">
                <Text className="text-sm">Body</Text>

                <CodeGenerator bodyObject={code} />
              </nav>

              <div className="mb-4 flex-1 rounded-lg">
                <CodeMirror
                  theme={codeEditorTheme}
                  autoFocus
                  value={code}
                  extensions={[json()]}
                  placeholder=""
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  data-color-mode={theme as 'light' | 'dark'}
                  style={{ lineHeight: '3.5', maxWidth: '100%', overflow: 'scroll' }}
                />
              </div>
            </Card>
          </div>
        </div>

        <Card className="group relative h-auto flex-1 !rounded-l-none !rounded-br-none border border-b-0 border-l-0 dark:!bg-zinc-950/10">
          <nav className="mb-2 flex items-center gap-x-2">
            <Text className="text-sm">Result</Text>
          </nav>

          <div className="overflow-y-scroll">{renderCodeEditorResult()}</div>
        </Card>
      </div>
    </div>
  )
}
