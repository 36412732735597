import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { DetailPageSkeleton } from 'components/skeletons'
import { RouteNames } from '@vectroid/shared/const'
import { useTrackRecentResources } from "modules/dashboard/store";
import { DatasetIndexHierarchyOverview } from 'modules/datasets/components/dataset-index-hierarchy-overview'
import { DatasetIndexesTable } from 'modules/datasets/components/dataset-indexes-table'
import { DatasetInsertEditor } from 'modules/datasets/components/dataset-insert-editor'
import { DatasetOverview } from 'modules/datasets/components/dataset-overview'
import { ShareDatasetDialog } from 'modules/datasets/components/share-dataset-dialog'
import { useDatasetDetail, useDatasetDetailStore } from 'modules/datasets/store/datase-detail.store'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export function DatasetDetailPage() {
  const { datasetName } = useParams<{ datasetName: string }>()
  const { fetchDatasetDetailPage, datasetLoading } = useDatasetDetail(datasetName!)
  const dataset = useDatasetDetailStore((state) => state.dataset)
  useTrackRecentResources();

  useEffect(() => {
    if (datasetName) {
      fetchDatasetDetailPage()
    }
  }, [datasetName, fetchDatasetDetailPage])

  if (datasetLoading) {
    return <DetailPageSkeleton />
  }

  if (!dataset) {
    return null
  }

  return (
    <>
      <Heading
        pageHeading
        breadcrumb={{ label: 'Datasets', path: RouteNames.Datasets }}
        actions={
          <>
            <ShareDatasetDialog datasetName={datasetName!} />
            <Button href={`${RouteNames.CreateIndex}?dataset=${datasetName}`} color="amber">
              New Index from this dataset
            </Button>
          </>
        }
      >
        <div className="flex items-center space-x-2">
          <span>{dataset?.name}</span>
        </div>
      </Heading>

      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12">
          <DatasetOverview />
        </div>
        <div className="col-span-7">
          <DatasetInsertEditor />
        </div>
        <div className="col-span-5">
          <DatasetIndexHierarchyOverview />
        </div>
        <div className="col-span-12">
          <DatasetIndexesTable />
        </div>
      </div>
    </>
  )
}
