import { CollectionType } from './collection-types'

const DATASET_COLLECTIONS = {
  [CollectionType.CREATE_DATASET]: {
    color: 'amber',
    method: 'POST',
    label: 'Create Dataset',
    endpoint: '/api/v1/dataset/create',
    type: CollectionType.CREATE_DATASET,
    snippets: {
      json: `{
  "name": "{{indexName}}",
  "dimensions": 96,
  "cloud": "GOOGLE",
  "region": "US_EAST"
}`,
    },
  },

  [CollectionType.LIST_DATASETS]: {
    color: 'green',
    method: 'GET',
    label: 'List Datasets',
    endpoint: '/api/v1/dataset',
    type: CollectionType.LIST_DATASETS,
  },

  [CollectionType.DESCRIBE_DATASET]: {
    color: 'green',
    method: 'GET',
    label: 'Describe Dataset',
    endpoint: '/api/v1/dataset/{datasetName}',
    type: CollectionType.DESCRIBE_DATASET,
  },

  [CollectionType.DATASET_INSERT]: {
    color: 'amber',
    method: 'POST',
    label: 'Upsert Data',
    endpoint: '/api/v1/dataset/insert',
    type: CollectionType.DATASET_INSERT,
    snippets: {
      json: `{
  "datasetName": "",
  "data": {
    "id": 1,
    "vector": [1.2, 2.3, 3.4],
    "metadata": {
      "key": "value"
    }
  }
}`,
    },
  },
}

export default DATASET_COLLECTIONS
