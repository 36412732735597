import { z } from 'zod'
import { CreateIndexFormSchema } from '../schema'

import { useNotificationStore } from 'components/common'
import { Form } from 'components/common/form/form'
import { Field, FieldTypes } from 'components/common/form/types'
import { RouteNames } from '@vectroid/shared/const'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getSimilarityFunctionOptions, SimilarityFunctions } from '@vectroid/shared/const'
import { createIndexApi } from '../services'
import { useIndexStore } from '../store'

const formSchema = CreateIndexFormSchema

type FormSchema = z.infer<typeof formSchema>

enum FieldNames {
  datasetName = 'datasetName',
  indexName = 'indexName',
  similarity = 'similarity',
}

const formFields: Field[] = [
  {
    name: FieldNames.datasetName,
    label: 'Select Dataset',
    fieldType: FieldTypes.DatasetSelect,
  },
  {
    name: FieldNames.indexName,
    label: 'Index Name',
    slugifyValue: true,
    renderHint: (value: string) => (value ? `Your index will be created as "<b>${value}</b>"` : undefined),
  },
  {
    name: FieldNames.similarity,
    fieldType: FieldTypes.Select,
    options: getSimilarityFunctionOptions(),
    label: 'Similarity Function',
  },
]

export function CreateIndexForm({ onCancel }: { onCancel?: () => void }) {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()
  const { fetchIndexes } = useIndexStore()
  const navigate = useNavigate()

  const defaultValues: FormSchema = {
    [FieldNames.datasetName]: '',
    [FieldNames.indexName]: '',
    [FieldNames.similarity]: SimilarityFunctions.Cosine,
  }

  async function onSubmit(values: FormSchema) {
    console.log('values', values)

    try {
      setLoading(true)
      setMessage(`<b>${values.indexName}</b> is creating now`, { type: 'loading' })

      const response = await createIndexApi({
        datasetName: values.datasetName,
        indexName: values.indexName,
        similarity: values.similarity,
        config: {
          name: values.indexName,
        },
      })

      setMessage(`<b>${values.indexName}</b> has been created. You are being redirected..`, { type: 'success' })
      onCancel?.()
      fetchIndexes()

      // TODO: Fix ID type
      setTimeout(() => {
        navigate(RouteNames.IndexDetailConsole.get(`${response.data.indexId}`, 'insert'))
      }, 1000)
    } catch (e: any) {
      setMessage(e.message, { type: 'error' })
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      formSchema={formSchema}
      formFields={formFields}
      defaultValues={defaultValues}
      loading={loading}
      actionBtnText="Create Index"
    />
  )
}
